export default {
  "ALREADY_LOGGED_IN": "Has logged",

  "AppSlitterSideTitle[shopDeliverySpLocker]": "Pickup Locations",

  "AppSplitterSideTitle[SignOut]": "Log Out",
  "AppSplitterSideTitle[other]": "Others",

  "BaseCountdownText[textD]": "d",
  "BaseCountdownText[textH]": "h",
  "BaseCountdownText[textM]": "m",

  "INVALID_TOKEN": "Invalid token",

  "JJ_Guide[footButton]": "Try out for free",
  "JJ_Guide[topButton]": "Skip",

  "JJ_ShopCampaignDetailGroupFoot[buttonPay]": "Group-buy Now",
  "JJ_ShopCampaignDetailNormalFoot[buttonPay]": "Buy Now",
  "JJ_ShopCampaign[tagMenuButton]": "Category",
  "JJ_ShopDeliverySpLocker[title]": "Pickup Locations",
  "JJ_ShopOrderBoxItem[contentViewRightViewButtonDescription]": "Please go to Self-pickup Locker to scan the QR code",
  "JJ_ShopOrderBoxItem[contentViewRightViewButton]": "Show QR code",
  "JJ_ShopOrderBoxItem[modalQrTitle]": "Order QR code",
  "JJ_ShopOrderBoxItem[pickupEndAtDescription]": "Note : Self-pickup Locker can only be reopened within 30 mins",
  "JJ_ShopOrderDetails[buttonGroup]": "Pay Deposit",
  "JJ_ShopOrderDetails[buttonNoraml]": "Pay Now",
  "JJ_ShopOrderListItem[humanIdTitle]": "Order No.: {humanId}",
  "JJ_ShopOrder[title]": "My Orders",
  "JJ_ShopTagMenu[title]": "Select a Category",

  "JJ_UserCouponListItem[no]": "None",

  "ModalPayment[demoMessage]": "This platform is for demo only",
  "ModalPayment[paymentButton]": "Pay Now",
  "ModalPayment[title]": "Please choose payment method",

  "ModalUserCoupon[button]": "View details",
  "ModalUserCoupon[title]": "You got New Coupon(s)",

  "appSideMenuChangeLanguageTitle": "Language",
  "appSideMenuMyItemsTitle": "My Items",

  "areaModuleDisabledMessage": "Sorry. System is currently down for maintenance. Please come back later!",

  "baseListNoMoreShopDataMessage": "No more items",
  "baseListPullToRefreshFinish": "Refreshed",
  "baseListPullToRefreshLoading": "Loading...",
  "baseListPullToRefreshPull": "Pull to refresh",
  "baseListPullToRefreshRelease": "Release to refresh",

  "boxPageButtonRefresh": "Refresh",

  "boxeStatus[COMPLETED]": "Already picked up",
  "boxeStatus[DELIVERED]": "Waiting for pickup",
  "boxeStatus[PENDING]": "Preparing for pickup",
  "boxeStatus[RECALLED]": "Pickup has expired",

  "buttonAddToCart": "Add to Cart",

  "buttonCancel": "Cancel",

  "buttonComplete": "Complete",

  "buttonDelete": "Delete",

  "buttonRefresh": "Refresh",

  "buttonStripePay": "Pay",

  "listItemRight[notSelected]": "Unselected",

  "loginAreaButton": "Confirm",

  "loginGuestLoginButton": "Have a look first",

  "loginMemberLoginButton": "Login",
  "loginMemberRegistrationButton": "Register",
  "loginMemberRegistrationInfo": "Register now to get your Coupons",

  "loginOrderInputOrderIdErrorInvalidFormat": "Incorrect format. Please check again",
  "loginOrderInputOrderIdPlaceholder": "XXX-XXX-XXX-XXX",

  "loginSms[TOO_EARLY]": "Sending too often. Please try again later",
  "loginSms[USER_NOT_FOUND]": "The phone number is not registered",
  "loginSms[title]": "Member login",

  "orderStatus[CANCELED]": "Canceled",
  "orderStatus[COMPLETED]": "Picked up",
  "orderStatus[DELIVERED]": "To be picked up",
  "orderStatus[EXPIRED]": "Expired",
  "orderStatus[PAID]": "Pending shipment",
  "orderStatus[PAID_CONFIRMED]": "Preparing for shipment",
  "orderStatus[PAID_ONGOING]": "Paid. Waiting campaign to complete",
  "orderStatus[PENDING]": "Unpaid",
  "orderStatus[PREPAID]": "Deposit Paid",
  "orderStatus[PREPAID_CONFIRMED]": "Pay the remaining",
  "orderStatus[PREPAID_ONGOING]": "Deposit Paid. Waiting campaign to complete",
  "orderStatus[RECALLED]": "Pickup has expired",
  "orderStatus[REFUNDED]": "Refunded",

  "pageErrorNotLoadMessage": "Failed to load",

  "registeredMemberSms[ALREADY_USER]": "Mobile No. has been registered. You may go to login",
  "registeredMemberSms[AREA_NOT_FOUND]": "Invalid Community. Please go back and try again",
  "registeredMemberSms[ConfirmButtonText]": "Confirm",
  "registeredMemberSms[DUPLICATE_PHONE]": "Phone number already exists",
  "registeredMemberSms[INVALID_PHONE_NUMBER]": "Please enter the correct telephone number",
  "registeredMemberSms[INVALID_TOKEN]": "Incorrect SMS verification code",
  "registeredMemberSms[SYSTEM_ERROR]": "System error. Please try again later",
  "registeredMemberSms[TOO_EARLY]": "Operation is too frequent. Please try again later",
  "registeredMemberSms[loginButton]": "Already registered?",
  "registeredMemberSms[phoneInputViewPlaceholder]": "Enter your mobile no.",
  "registeredMemberSms[sendVerificationCodeSuccess]": "Verification code has been sent. Please check your mobile phone SMS. It might take minutes depending on your mobile ISP",
  "registeredMemberSms[title]": "Register",
  "registeredMemberSms[verificationInputViewButton]": "Send",
  "registeredMemberSms[verificationInputViewOffSentInfo]": "Enter your mobile phone number and click Send Code",
  "registeredMemberSms[verificationInputViewOnSentInfo]": "Verification code has been sent. Please check your mobile phone SMS. It might take minutes depending on your mobile ISP",
  "registeredMemberSms[verificationInputViewPlaceholder]": "Enter the SMS code",
  "registeredMemberSms[verificationResendInputViewButton]": "Resend",

  "shopCampaign": "Shop Campaign",
  "shopCampaignAmountShown": "number} units",
  "shopCampaignCountdownTime": "Expires at {datetime}",
  "shopCampaignDettailsCampaignMemberLogin": "You must register as a member to proceed purchase",
  "shopCampaignDettailsCampaignNotAvailable": "This item is unavailable for purchase now",
  "shopCampaignDettailsPackageNotFound": "This item has been removed",
  "shopCampaignDettails[EXCEED_PACKAGE_USER_AMOUNT_LIMIT]": "This item is sold out",
  "shopCampaignDettails[NOT_ENOUGH_INVENTORY]": "Inventory is not enough. Please try again later.",
  "shopCampaignGroupConfirmedAmountUnit": "units",
  "shopCampaignGroupPriceText": "Group-buy Price",
  "shopCampaignGroupStatus[CANCELED]": "Canceled",
  "shopCampaignGroupStatus[CONFIRMED]": "Shipping",
  "shopCampaignGroupStatus[ENDED]": "Campaign Ended",
  "shopCampaignGroupStatus[ONGOING]": "Confirming orders",
  "shopCampaignGroupStatus[UPCOMING]": "Upcoming",
  "shopCampaignNormalPriceText": "Discount",
  "shopCampaignNormalStatus[ENDED]": "Unavailable for now",
  "shopCampaignNormalStatus[ONGOING]": "Available for purchase",
  "shopCampaignNormalStatus[UPCOMING]": "Upcoming for purchase",
  "shopCampaignOrder": "My Orders",
  "shopCampaignPackage": "Items",
  "shopCampaignPriceOriginalText": "Supermarket Price",
  "shopCampaignRemainingTime": "End at ",
  "shopCampaignShopModalButtonAddToCart": "Add to Cart",
  "shopCampaignShopModalButtonPay": "Buy now",
  "shopCampaignTagAll": "All",

  "shopCampaingListNoDateText": "There will be upcoming goods. Please stay tuned!",

  "shopCartCampaignPackagesModal[amountText]": "Total",
  "shopCartCampaignPackagesModal[noDateText]": "No items yet",
  "shopCartCampaignPackagesModal[payButton]": "Pay Now",

  "shopOrderCalculatedAdditionalAmount": "additional fee included",
  "shopOrderCalculatedAdditionalShippingAmount": "shipping fee included",
  "shopOrderDeatils[orderPaymentTransactionsTitle]": "Payment method",
  "shopOrderDeatils[typeTitle]": "Type",
  "shopOrderDeliveryButtonSelect": "Select Delivery Method",
  "shopOrderDeliveryRecipientSelectTitle": "Select Recipient",
  "shopOrderDeliveryRecipientTitle": "Delivery recipient",
  "shopOrderDeliverySelectTitle": "Select Delivery Method",
  "shopOrderDeliveryTitle": "Delivery Method",
  "shopOrderDetailCancel": "Cancel Order",
  "shopOrderDetailCreatedAt": "Created at",
  "shopOrderDetailDeleteOrder": "Delete Order",
  "shopOrderDetailInfo": "Order Items",
  "shopOrderDetailPriceOriginal": "Original Purchase Amount",
  "shopOrderDetailsErrorCode[CANCEL_NOT_ALLOWED]": "Unable to cancel this order",
  "shopOrderDetailsErrorCode[NOT_FOUND]": "Unable to find this order",
  "shopOrderDetailsErrorCode[NO_PAYMENT_NEEDED]": "System errors. Please retry",
  "shopOrderDetailsErrorCode[ORDER_IS_PAID]": "Order has been paid",
  "shopOrderDetailsErrorCode[PAYMENT_SYSTEM_ERROR]": "Payment system errors. Please try again later or use other payment methods",
  "shopOrderDetailsErrorCode[WALLET_NOT_ENOUGHT_BALANCE]": "Sorry. Your balance is not enough",
  "shopOrderDetails[boxes]": "Pickup Packages",
  "shopOrderDetails[deleteShopOrderSuccess]": "Order deleted successfully",
  "shopOrderDetails[logisticsDisplay]": "Logistics tracking",
  "shopOrderDetails[noPayment]": "This {name} payment is unavailable in temporary time",
  "shopOrderDetails[orderDeliveryViewTitle]": "Delivery info",
  "shopOrderDetails[orderDiscount]": "Discounted amount",
  "shopOrderDetails[orderInfoViewTitle]": "Order info",
  "shopOrderDetails[paymenError]": "Failed to pay the order",
  "shopOrderDetails[paymenSuccess]": "Order has been paid successfully",
  "shopOrderDetails[putShopOrderSuccess]": "Order has been edited successfully",
  "shopOrderDetails[shopOrderCancelSuccess]": "Order has been canceled successfully",
  "shopOrderDetails[title]": "Order details",
  "shopOrderListNoDateText": "No orders yet",
  "shopOrderQrCode[CANCELED]": "Canceled",
  "shopOrderQrCode[COMPLETED]": "Picked up",
  "shopOrderQrCode[EXPIRED]": "Pickup expired",
  "shopOrderQrCode[RECALLED]": "Items have been recalled",
  "shopOrderQrCode[REFUNDED]": "Refunded",
  "shopOrderRecipientButtonSelect": "Select Recipient",
  "shopOrderStatusCompleted": "Completion",
  "shopOrderStatusCompletedDescription": "Completed",
  "shopOrderStatusDelivered": "To be picked up",
  "shopOrderStatusDeliveredDirectDescription": "User receiving directly",
  "shopOrderStatusDeliveredSelfPickupDescription": "User coming to pick up",
  "shopOrderStatusDeliveredSpLockerDescription": "User collecting from SP Lockers",
  "shopOrderStatusPaid": "Pending",
  "shopOrderStatusPaidDescription": "Packing items",
  "shopOrderStatusPaidDirect": "Pending",
  "shopOrderStatusPaidSpLockerDescription": "Items being allocated to SP Lockers",
  "shopOrderStatusPending": "Waiting to pay",
  "shopOrderStatusPendingDescription": "User payment",
  "shopOrderTotalAmount": "Total",

  "userCouponList[ButtonNotSelect]": "Please select",
  "userCouponList[noDataViewText]": "No coupons yet",
  "userCoupon[loadMoreEnd]": "No more coupons",
  "userCoupon[title]": "My Coupons",

  "userDeliveryAddressButtonAdd": "Add Delivery Address",
  "userDeliveryAddressButtonEdit": "Edit Delivery Address",
  "userDeliveryDeleteFailed": "Delete Failed",
  "userDeliveryDeleteSuccess": "Delete Successful",
  "userDeliveryRecipientButtonAdd": "Add Recipient Contact",
  "userDeliveryShippingFeeWavied": "Free Shipping",
  "userDeliveryShippingFeeWaviedDescription": "Free Shipping for purchase > {number}",

  "总价": "Total",

  "总共商品": "number} Items",

  "折后价格": "Discounted Purchase Amount",

  "提交": "Submit",

  "提取码": "Extraction code",

  "收货人": "Receiver",

  "数量": "Amount",

  "查询": "Track my Order",

  "正常": "NORMAL",

  "添加失败": "Add failure",

  "添加成功": "Add success",

  "确定": "Confirm",

  "立刻购买": "Quick Checkout",

  "编辑失败": "Editor failure",

  "编辑成功": "Editorial success",

  "联系地址": "Contact address",

  "联系电话": "Contact number",

  "自提柜": "Machine",

  "规格": "Details",

  "订单": "Order",

  "订单编号": "Order No.",

  "订单详情": "Order details",

  "设置默认": "set default",

  "详情": "Details",

  "请输入": "Please enter",

  "请输入订单编号登录": "Please Enter your Order No.",

  "请选择区域登录": "Please select your region",

  "请选择登录方式": "Please select",

  "购物车": "Shopping Cart",

  "输入银行卡": "Enter your Credit Card No.",

  "运费": "Shipping Fee",

  "重新加载": "Reload",

  "附加费": "Additional Fee",

  "限购数量": "Sales quota : { number } pieces"
}