import Type from './ActionType';
import api, { GetUserShopDeliveryAddressRes, GetUserShopDeliveryAddressListParameters, GetUserShopDeliveryAddressParameters } from '../api';
import { MyThunkDispatch } from './action-interface';
import tool from '../tool';

const upDataAction = (data: ActionShopDeliveryAddressRes) => {
    return {
        type: Type.shopDeliveryAddressType.UP_DATA,
        ...data
    }
}

const initDataAction = () => {
    return {
        type: Type.shopDeliveryAddressType.INIT_DATA
    }
}

interface ActionShopDeliveryAddressListRes {
    [id: string]: GetUserShopDeliveryAddressRes
}
export interface ActionShopDeliveryAddressRes {
    list: ActionShopDeliveryAddressListRes
}

export const getUserShopDeliveryAddressList = (params: GetUserShopDeliveryAddressListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUserShopDeliveryAddressList(params)
        if (res.kind === 'ok') {
            const shopDeliveryAddress: ActionShopDeliveryAddressRes = tool.store.getState().shopDeliveryAddress
            res.data.data.forEach(item => {
                shopDeliveryAddress.list[item.id] = item
            })
            dispatch(upDataAction(shopDeliveryAddress))
        }
        return res
    }
}
export const getUserShopDeliveryAddress = (params: GetUserShopDeliveryAddressParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUserShopDeliveryAddress(params)
        if (res.kind === 'ok') {
            const shopDeliveryAddress: ActionShopDeliveryAddressRes = tool.store.getState().shopDeliveryAddress
            shopDeliveryAddress.list[res.data.id] = res.data
            dispatch(upDataAction(shopDeliveryAddress))
        }
        return res
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}












