import React from 'react';
import { Modal, ActivityIndicator } from 'antd-mobile'
import *as Ons from 'react-onsenui'
import api, { GetPaymentListParameters, GetPaymentRes } from '../../api';
import { FaSadTear } from 'react-icons/fa';
import tool from '../../tool';
import BoxNoDataPage from '../Box/BoxNoDataPage';


interface PageState {
    paymentList?: GetPaymentRes[]
    selectIndex: number
    loadType: 'load' | 'error' | 'normal'
}

export interface Props {
    show: boolean
    amount: number
    onClose(): void
    onPaymen(payment: GetPaymentRes): void
}

type PageProps = Props



class ModalPayment extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            loadType: 'load',
            selectIndex: 0,
        }
    }


    componentWillReceiveProps(newProps: PageProps) {
        if (newProps.show !== this.props.show) {
            if (newProps.show) {
                this._initData(newProps)
            }
        }
    }

    _initData = async (props: PageProps) => {
        try {
            this.setState({ loadType: 'load' })
            const paymentListRes = await this._getPaymentList({ count: 1000, amount: props.amount })

            this.setState({
                loadType: 'normal',
                paymentList: paymentListRes.data,
            })
        } catch (error) {
            this.setState({ loadType: 'error' })
            console.error(error)
        }
    }


    _getPaymentList = async (params: GetPaymentListParameters) => {
        const res = await api.getPaymentList(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
        }
        throw new Error(res.kind)
    }

    render() {

        const loadView = () => {
            return (
                <div className='loadView'>
                    <ActivityIndicator
                        animating={true}
                    />

                </div>
            )
        }

        const normalView = () => {

            if (this.state.paymentList) {

                const renderRow = (payment: GetPaymentRes, index: number) => {

                    return (
                        <Ons.ListItem
                            className={'paymentItem'}
                            key={payment.id}
                            onClick={() => {
                                this.setState({ selectIndex: index })
                            }}
                            modifier={'longdivider'}
                            tappable
                        >
                            <div className="left">
                                <Ons.Radio
                                    inputId={`radio-${index}`}
                                    checked={index === this.state.selectIndex}
                                />
                            </div>
                            <div className="center">

                                <img
                                    className='thumbnail'
                                    src={payment.thumbnail.url}
                                />

                                <div
                                    className='centerBoxRigthBox'
                                >
                                    <span
                                        className='name'
                                    >
                                        {payment.name}
                                    </span>
                                    <span
                                        className='description'
                                    >
                                        {payment.description}
                                    </span>
                                    {(payment.calculatedAdditionalAmount !== undefined && payment.calculatedAdditionalAmount > 0) && (
                                        <span
                                            className='calculatedAdditionalAmount'
                                        >
                                            ${tool.math.initPrice({ price: payment.calculatedAdditionalAmount }).toFixed(2)}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Ons.ListItem>
                    )
                }

                const calculatedAdditionalAmount = this.state.paymentList[this.state.selectIndex].calculatedAdditionalAmount || 0
                const amount = this.props.amount + calculatedAdditionalAmount
                return (
                    <div className='normalView'>
                        <h1 className='title'>{tool.intl.formatMessage({ id: 'ModalPayment[title]' })}</h1>
                        <Ons.List
                            className='paymentList'
                            dataSource={this.state.paymentList}
                            renderRow={renderRow}
                        />

                        <div className='paymentButtonContainer'>
                            <Ons.Button
                                className={'paymentButton'}
                                disabled={this.state.selectIndex === undefined}
                                modifier={'large'}
                                onClick={() => {
                                    this.state.paymentList && tool.message.info({
                                        content: tool.intl.formatMessage({ id: 'ModalPayment[demoMessage]' }),
                                    })
                                }}
                            >
                                {`${tool.intl.formatMessage({ id: 'ModalPayment[paymentButton]' })}${`  $${tool.math.initPrice({ price: amount }).toFixed(2)}`}`}
                            </Ons.Button>
                        </div>

                    </div >
                )
            }

            return (
                <BoxNoDataPage
                    title={'没有数据'}
                    onClick={() => this._initData(this.props)}
                />
            )

        }

        const errorView = () => {
            return (
                <div className='errorView'>
                    <FaSadTear className='icon' />
                    <div>{tool.intl.formatMessage({ id: 'pageErrorNotLoadMessage' })}</div>
                    <Ons.Button
                        onClick={() => this._initData(this.props)}
                        className='btn'
                    >
                        {tool.intl.formatMessage({ id: 'boxPageButtonRefresh' })}
                    </Ons.Button>
                </div>
            )
        }

        return (
            <Modal
                popup={true}
                visible={this.props.show}
                onClose={() => this.props.onClose()}
                animationType="slide-up"
            >
                <div
                    id='ModalPayment'
                >
                    {this.state.loadType === 'error' && errorView()}
                    {this.state.loadType === 'normal' && normalView()}
                    {this.state.loadType === 'load' && loadView()}
                </div>
            </Modal>
        );
    }
}

export default ModalPayment






