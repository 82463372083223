import React from 'react';

import JJ_Components from '../../../components/JJ_Components'
import { GetShopOrderRes, GetShopCampaignRes } from '../../../api';
import tool from '../../../tool';
import moment from 'moment';


interface PageState {

}

export interface Props {

    callTimeLeftAction: () => {
    }
    order: GetShopOrderRes

    campaign: GetShopCampaignRes

}

type PageProps = Props

class JJ_ShopOrderDetailHeadView extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
    }



    _getOrderStatusText = (order: GetShopOrderRes) => {
        const groupConfirmedAmount = this.props.campaign.groupConfirmedAmount
        const groupOrderedAmount = this.props.campaign.groupOrderedAmount
        const progress = parseInt(`${groupOrderedAmount / groupConfirmedAmount * 100}`) || 0


        switch (this.props.campaign.type) {
            case 'GROUP': {
                switch (order.status) {
                    case 'PENDING': {

                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[PENDING]' })}
                                </span>
                                <span
                                    className={'endAtText'}
                                >

                                    {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                        datetime: moment(order.expiredAt).format('YYYY-MM-DD HH:mm'),
                                    })}  `}

                                    {}
                                </span>

                            </>
                        )

                    }
                    case 'PREPAID': {

                        if (this.props.campaign.status === 'ONGOING') {
                            //進行中
                            //等待成团结束
                            const endDate = this.props.campaign.endAt || ''
                            return (
                                <>
                                    <JJ_Components.baseProgressView
                                        className='progressView'
                                        progress={progress || 0}
                                    >
                                        <span
                                            className='title'
                                        >
                                            {tool.intl.formatMessage({ id: 'orderStatus[PREPAID_ONGOING]' })}
                                        </span>
                                    </JJ_Components.baseProgressView>

                                    <span
                                        className={'endAtText'}
                                    >

                                        {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                            datetime: moment(endDate).format('YYYY-MM-DD HH:mm'),
                                        })}  `}

                                        {/* {`${tool.intl.formatMessage({ id: 'shopCampaignRemainingTime' })}  `}
                                        <JJ_Components.baseCountdownText
                                            key={order.status}
                                            beginDate={new Date()}
                                            endDate={new Date(endDate)}
                                            onEnd={() => {
                                                this.props.callTimeLeftAction()
                                            }}
                                        /> */}
                                    </span>

                                </>
                            )

                        }

                        if (this.props.campaign.status === 'CONFIRMED') {
                            //已成團
                            //成团成功，等待支付余额
                            return (
                                <>
                                    <span
                                        className='title'
                                    >
                                        {tool.intl.formatMessage({ id: 'orderStatus[PREPAID_CONFIRMED]' })}
                                    </span>

                                </>
                            )
                        }

                        return null

                    }
                    case 'PAID': {

                        if (this.props.campaign.status === 'ONGOING') {
                            //已经支付
                            //等待成团结束
                            const endDate = this.props.campaign.endAt || ''
                            return (
                                <>
                                    <JJ_Components.baseProgressView
                                        className='progressView'
                                        progress={progress || 0}
                                    >
                                        <span
                                            className='title'
                                        >
                                            {tool.intl.formatMessage({ id: 'orderStatus[PAID_ONGOING]' })}
                                        </span>
                                    </JJ_Components.baseProgressView>

                                    <span
                                        className={'endAtText'}
                                    >

                                        {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                            datetime: moment(endDate).format('YYYY-MM-DD HH:mm'),
                                        })}  `}
                                    </span>

                                </>
                            )

                        }

                        if (this.props.campaign.status === 'CONFIRMED') {
                            //已经支付
                            //等待发货
                            return (
                                <>
                                    <span
                                        className='title'
                                    >
                                        {tool.intl.formatMessage({ id: 'orderStatus[PAID_CONFIRMED]' })}
                                    </span>

                                </>
                            )
                        }

                        return null

                    }
                    case 'DELIVERED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[DELIVERED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'EXPIRED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[EXPIRED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'CANCELED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[CANCELED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'COMPLETED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[COMPLETED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'RECALLED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[RECALLED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'REFUNDED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[REFUNDED]' })}
                                </span>

                            </>
                        )

                    }
                    default: return (
                        <>
                            <span
                                className='title'
                            >
                                ???????
                            </span>

                        </>
                    )
                }
            }
            case 'FLASH':
            case 'NORMAL': {
                switch (order.status) {
                    case 'PENDING': {

                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[PENDING]' })}
                                </span>
                                <span
                                    className={'endAtText'}
                                >

                                    {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                        datetime: moment(order.expiredAt).format('YYYY-MM-DD HH:mm'),
                                    })}  `}
                                </span>

                            </>
                        )

                    }
                    case 'PREPAID': {

                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[PREPAID]' })}
                                </span>
                                <span
                                    className={'endAtText'}
                                >

                                    {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                        datetime: moment(order.expiredAt).format('YYYY-MM-DD HH:mm'),
                                    })}  `}
                                </span>

                            </>
                        )

                    }
                    case 'PAID': {



                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[PAID]' })}
                                </span>

                            </>
                        )

                    }
                    case 'DELIVERED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[DELIVERED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'EXPIRED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[EXPIRED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'CANCELED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[CANCELED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'COMPLETED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[COMPLETED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'RECALLED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[RECALLED]' })}
                                </span>

                            </>
                        )

                    }
                    case 'REFUNDED': {
                        return (
                            <>
                                <span
                                    className='title'
                                >
                                    {tool.intl.formatMessage({ id: 'orderStatus[REFUNDED]' })}
                                </span>

                            </>
                        )

                    }
                    default: return (
                        <>
                            <span
                                className='title'
                            >
                                ???????
                            </span>

                        </>
                    )
                }
            }
        }

    }

    render() {
        return (
            <div
                id='JJ_ShopOrderDetailHeadView'
            >
                {this._getOrderStatusText(this.props.order)}

            </div>
        );
    }
}


export default JJ_ShopOrderDetailHeadView
