import React from 'react';
import JJ_Components from '../../components/JJ_Components'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import {
    CardElement,
    Elements,
    injectStripe,
    ReactStripeElements
} from 'react-stripe-elements'
import { GetPaymentRes } from '../../api';
import tool from '../../tool';


const createOptions = (fontSize = 19, ) => {
    return {
        style: {
            base: {
                fontSize,
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
            complete: {
                fontSize,
            }
        },
    };
};




class _CardForm extends React.Component<ReactStripeElements.InjectedStripeProps & ReactStripeElements.ElementProps & { ref: any }> {

    handleSubmit = (ev: { preventDefault: () => void; }) => {
        ev.preventDefault();
        if (this.props.stripe) {
            this.props.stripe.createToken()
                .then((payload: any) => console.log('[token]', payload));
        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };


    render() {

        return (
            <CardElement
                {...this.props}
                {...createOptions}
            />
        );
    }
}

const CardForm = injectStripe(_CardForm)


interface PageState {
    cardParams?: stripe.elements.ElementChangeResponse
}

export interface Props {
    payment: GetPaymentRes
    onPayment(props: PageProps & { tokenId: string }): void
    price: number
}

type PageProps = Props

class JJ_StripePay extends React.Component<PageProps, PageState>{

    refCard: null | CardElement | undefined




    constructor(props: PageProps) {
        super(props);
        this.state = {

        }
        this.refCard = null
    }


    _initData = async () => {
        try {

        } catch (error) {
            console.error(error)
        }
    }

    _onPayAction = async () => {

        try {
            if (this.refCard) {
                tool.message.loading({})
                const state: any = this.refCard.state
                state.stripe.createToken()
                    .then((payload: any) => {
                        tool.message.hide()
                        console.log('createToken', payload)
                        const tokenId: string = payload.token.id
                        this.props.onPayment({ ...this.props, tokenId })
                    })
                    .catch((e: { message: any; }) => {
                        tool.message.hide()
                        e.message && tool.dialog.toast({ content: e.message })
                        console.log(e)
                    })
            }


        } catch (error) {
            error.message && tool.dialog.toast({ content: error.message })
            console.error(error)
        }



    }


    _btnPay = () => {
        return (
            <Ons.Button
                className='btnPay'
                modifier='large'
                disabled={!(this.state.cardParams && this.state.cardParams.complete)}
                onClick={() => this._onPayAction()}
            >
                {tool.intl.formatMessage({ id: 'buttonStripePay' })}
            </Ons.Button>
        )
    }



    _cardDetailsView = () => {


        return (
            <div className='cardDetailsView'>
                <Elements>
                    <CardForm
                        ref={(ref: any) => this.refCard = ref}
                        hidePostalCode={true}
                        disabled={false}
                        className={'cardElement'}
                        onChange={e => {
                            this.setState({
                                cardParams: e
                            })
                        }}
                    />

                </Elements>
            </div>
        )
    }

    _view = (payment: GetPaymentRes) => {
        let price: number = this.props.price + (payment.calculatedAdditionalAmount || 0)

        return (
            <div
                id='JJ_StripePay'
            >
                <div
                    className='body'
                >
                    <span className='price'> <span
                        style={{ fontSize: 30 }}>$</span>{tool.math.initPrice({ price }).toFixed(2)}</span>
                    <span className='title'>{tool.intl.formatMessage({ id: '输入银行卡' })}</span>
                    <span className='calculatedAdditionalAmount'>{payment.description} ${tool.math.initPrice({ price: payment.calculatedAdditionalAmount || 0 }).toFixed(2)}</span>
                    {this._cardDetailsView()}
                </div>
                {this._btnPay()}
            </div>
        )
    }


    render() {

        const payment = this.props.payment
        const nav = {
            showLeftIcon: true,
            title: payment.name,
        }
        return (
            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>
                <JJ_Components.boxPage
                    loadData={this._initData}
                >
                    {this._view(payment)}
                </JJ_Components.boxPage>
            </Ons.Page>
        );
    }
}




export default JJ_StripePay