import React from 'react'
import queryString from 'query-string';
import { connect, ConnectedProps } from 'react-redux'
import JJ_ShopCampaignList from './PageItem/JJ_ShopCampaignList'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import { injectIntl, InjectedIntlProps } from "react-intl";
import { Modal, Tabs, Icon } from 'antd-mobile'
import { ActionAreaRes } from '../../actions/ActionArea'
import *as ActionModal from '../../actions/ActionModal'
import tool from '../../tool'
import api, { GetShopCartCampaignTagRes, GetShopCartCampaignTagParameters, GetAdvertRes, GetAdvertListParameters } from '../../api'
import BoxPage from '../../components/Box/BoxPage'
import menuPng from '../../imgs/menu.png'
import JJ_Modal from './PageItem/JJ_Modal'
import JJ_Components from '../../components/JJ_Components'
import config from '../../config/config'


interface PageState {
    tagsData: GetShopCartCampaignTagRes[]
    selectedTagId: string
    index: number
    showServicePrompt: boolean
    adverts?: GetAdvertRes[]

}


export interface Props {
    tags: string[]
    showMenu?(): void

}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>




interface RootState {
    area: ActionAreaRes
}

const mapState = (state: RootState) => ({
    areaData: state.area
})

const mapDispatch = {
    showUserCouponFeedModal: ActionModal.showUserCouponFeedModal
}

export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_ShopCampaign extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {
            tagsData: [],
            index: 0,
            showServicePrompt: false,
            selectedTagId: '',
        }
    }


    componentDidMount() {
        this._loadServicePrompt()
        this._localStoragePushAction()
        tool.intl.getIntl(this.props.intl)
        this.props.showUserCouponFeedModal()

    }

    _initData = async () => {
        const moduleData = this.props.areaData.moduleData.find(item => item.type === 'SHOP')
        try {
            const tagsData: GetShopCartCampaignTagRes[] = [];

            const [_, getAdvertListRes] = await Promise.all([
                Promise.all(this.props.tags.map(id => {
                    return this._getShopCartCampaignTag({ id })
                        .then(tagRes => tagsData.push(tagRes))
                        .catch(err => {});
                })),
                this._getAdvertList({
                    position: `home:top:${moduleData ? moduleData.id : ''}`,
                    count: 1000,
                }),
            ]);

            this.setState({
                tagsData,
                adverts: getAdvertListRes.totalCount > 0 ? getAdvertListRes.data : undefined
            })
            return tagsData
        } catch (error) {
            throw new Error(error)

        }

    }



    /**
     * 获取活动标签数据
     */
    _getShopCartCampaignTag = async (params: GetShopCartCampaignTagParameters) => {

        const res = await api.getShopCartCampaignTag(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }

    }

    _getAdvertList = async (params: GetAdvertListParameters) => {

        const res = await api.getAdvertList(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            // switch (res.kind) {
            //     case 'INVALID_PARAMS': {

            //     }
            // }
            // throw new Error(res.kind)
            return {
                data: [],
                totalCount: 0,
            }
        }

    }



    _localStoragePushAction = () => {
        const pushActionRes = tool.storage.getItem('pushAction')
        console.log('_localStoragePushAction', pushActionRes)
        const moduleData = this.props.areaData.moduleData.find(item => item.type === 'SHOP')
        if (pushActionRes) {
            switch (pushActionRes.key) {
                case 'pushCampaignDetails': {

                    moduleData && tool.navigator.pushPage({
                        key: 'JJ_ShopCampaignDetails',
                        props: { id: pushActionRes.data.id, moduleId: moduleData.id }
                    })

                }
                    break
                case 'pushShopOrderDetails': {

                    moduleData && tool.navigator.pushPage({
                        key: 'JJ_ShopOrderDetails',
                        props: { orderId: pushActionRes.data.id, moduleId: moduleData.id }
                    })
                }
                    break
            }
        }
        tool.storage.removeItem('pushAction')
    }

    _renderHeader = (adverts: GetAdvertRes[]) => {

        return (
            <div>
                <JJ_Components.baseBannerAdvert
                    // style={{ marginBottom: 10}}
                    adverts={adverts}
                    autoplay={true}
                    infinite={true}
                    dots={true}
                    autoplayInterval={5000}
                    onClick={e => {
                        if (e.link) {
                            window.open(e.link)
                        }
                    }}
                />
                <div style={{
                    height: 10,
                    backgroundColor: 'rgba(114, 197, 197, 0.658)'
                }}
                />
            </div>

        )
    }

    _renderContent = (tagData: GetShopCartCampaignTagRes) => {
        const moduleData = this.props.areaData.moduleData.find(item => item.type === 'SHOP')

        return moduleData && (
            <JJ_ShopCampaignList
                renderHeader={this.state.adverts ? () => this.state.adverts ? this._renderHeader(this.state.adverts) : <div /> : undefined}
                tagName={tagData.name}
                moduleId={moduleData.id}
                tag={tagData.id}
            />
        )
    }


    _view = () => {
        const tags = [
            // {
            //     title: tool.intl.formatMessage({ id: 'shopCampaignTagAll' }),
            //     name: tool.intl.formatMessage({ id: 'shopCampaignTagAll' }),
            //     id: undefined,
            // },
            ...this.state.tagsData.map((item: { name: any, id: string }) => {
                return {
                    ...item,
                    title: item.name,
                    key: item.id,
                }
            }),
        ]
        // return (
        //     <div
        //         id='JJ_ShopCampaign'
        //     >
        //         {this._renderContent({
        //             id: '',
        //             isVisible: true,
        //             name: 'string'
        //         })}
        //     </div>
        // )
        // 是否启动Tabs？
        return (
            <div
                id='JJ_ShopCampaign'
            >
                <Tabs
                    tabs={tags}
                    renderTabBar={props => <Tabs.DefaultTabBar {...props} page={2.5} />}
                    page={this.state.selectedTagId}
                    onChange={(tab: any) => this.setState({
                        selectedTagId: tab.key,
                    })}
                    swipeable={false}
                >
                    {this._renderContent}
                </Tabs>

            </div>
        )
    }

    /**
     * 加载服务提示
     * 第一次加载时会弹出提示警告
     */
    _loadServicePrompt = () => {

        // // check webview
        // const urlParams = queryString.parse(location.search);
        // const isWebview = urlParams.view === 'webview';

        // if (isWebview) {
        //     return;
        // }

        // const storage = window.localStorage
        // const key = 'servicePrompt'

        // if (storage[key]) {
        //     return
        // }

        // this.setState({ showServicePrompt: true })
        // storage[key] = 'servicePrompt'
    
    }

    render() {
        const nav = {
            showLeftIcon: false,
            leftIcon: this.state.tagsData.length ? (
                <Ons.Button
                    onClick={() => tool.navigator.pushPage({
                        key: 'JJ_ShopTagMenu',
                        props: {
                            tagsData: this.state.tagsData,
                            onChange: (selectedTagId: string) => {
                                this.setState({
                                    selectedTagId,
                                })
                            }
                        }
                    })}
                    className='btn'
                >
                    {tool.intl.formatMessage({ id: 'JJ_ShopCampaign[tagMenuButton]' })}
                </Ons.Button>
            ) : null,
            rightIcon: (

                <img
                    style={{
                        height: 25,
                        width: 25,
                        padding: 10
                    }}
                    src={menuPng}
                    onClick={() => this.props.showMenu && this.props.showMenu()}

                />

            )
        }
        return (
            <Ons.Page
                renderToolbar={() => <JJ_Nav {...nav} />}
            >
                <BoxPage
                    loadData={this._initData}
                >

                    {this._view()}
                    <Modal
                        visible={this.state.showServicePrompt}
                        onClose={() => this.setState({ showServicePrompt: false })}
                        transparent
                        style={{
                            width: '90vw',
                        }}
                        title={config.servicePrompt.title[this.props.intl.locale]}
                        animationType="fade"
                        footer={[{
                            text: 'Ok', onPress: () => {
                                this.setState({ showServicePrompt: false })
                            }
                        }]}
                    >
                        <div
                            id='JJ_ShopCampaignServicePrompt'
                        >
                            <img
                                src={config.servicePrompt.img[this.props.intl.locale]}
                            />
                            <span>
                                {config.servicePrompt.details[this.props.intl.locale]}
                            </span>
                        </div>
                    </Modal>
                    <JJ_Modal />
                </BoxPage>

            </Ons.Page>


        )

    }
}


export default connector(injectIntl(JJ_ShopCampaign))



