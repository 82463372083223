import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { injectIntl, InjectedIntlProps } from "react-intl";
import tool from '../../tool';
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav';
import { Button } from 'antd-mobile'
interface PageState {

}


interface Props {
    dispatch: any
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface Page {

}


interface RootState {
    user: any
}

const mapState = (state: RootState) => ({
    userData: state.user,

})

const mapDispatch = {

}

export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_Login extends React.Component<PageProps, PageState> implements Page {

    constructor(props: PageProps) {
        super(props);

        tool.navigator.pushPage({ key: 'JJ_LoginArea', props: { action: { type: 'postAuthSessionRequestNew' } }})
    }

    static defaultProps = {};




    btnView = () => {

        return (
            <div className='btnView' >
                <h1>{this.props.intl.formatMessage({ id: '请选择登录方式' })} </h1>

                <Ons.Button
                    onClick={() => tool.navigator.pushPage({ key: 'JJ_LoginSms', props: {} })}
                    modifier='large outline'
                    style={{ marginTop: 10, width: '80%' }}
                >
                    {this.props.intl.formatMessage({ id: 'loginMemberLoginButton' })}
                </Ons.Button>
                <Ons.Button
                    onClick={() => tool.navigator.pushPage({ key: 'JJ_LoginArea', props: { action: { type: 'postAuthSmsRequestNew' } } })}
                    modifier='large'
                    style={{ marginTop: 10, width: '80%', opacity: 0.7 }}
                >
                    {this.props.intl.formatMessage({ id: 'loginMemberRegistrationButton' })}
                </Ons.Button>
                <span
                    className='loginMemberRegistrationInfo'
                    style={{ marginTop: 10, width: '80%' }}
                >
                </span>
                <Ons.Button
                    onClick={() => tool.navigator.pushPage({ key: 'JJ_LoginArea', props: { action: { type: 'postAuthSessionRequestNew' } } })}
                    modifier='large light'
                    style={{ marginTop: 10, width: '80%' }}
                >
                    {this.props.intl.formatMessage({ id: 'loginGuestLoginButton' })}
                </Ons.Button>

            </div>
        )
    }


    render() {
        const nav = {
            showLeftIcon: false,
            showRightIcon: false,
            ...this.props
        }
        return (
            <Ons.Page renderToolbar={() => (<JJ_Nav {...nav} />)}>

                <div
                    id='JJ_Login'
                    className='box'
                >
                    {/*this.btnView()*/}
                </div>
            </Ons.Page>

        )
    }
}

export default connector(injectIntl(JJ_Login))


