
import { ConfigType, Guide } from './config.type'

//配置開發模式
class ConfigDev implements ConfigType {

    

    groupOrderPaymentDescription: ConfigType['groupOrderPaymentDescription'] = {
        title: {
            'en': 'Contact us',
            'zh-tw': '聯繫我們',
            'zh-cn': '联系我们',
        },
        details: {
            'en': 'I BUILD CONCEPTS (SYSTEM) PTY LTD\nUNIT 26, 374-378 LYGON STREET, BRUNSWICK EAST VIC 3057\nE-mail:  joey@i-b-c.au',
            'zh-tw': 'I BUILD CONCEPTS (SYSTEM) PTY LTD\nUNIT 26, 374-378 LYGON STREET, BRUNSWICK EAST VIC 3057\nE-mail:  joey@i-b-c.au',
            'zh-cn': 'I BUILD CONCEPTS (SYSTEM) PTY LTD\nUNIT 26, 374-378 LYGON STREET, BRUNSWICK EAST VIC 3057\nE-mail:  joey@i-b-c.au',
        }
    }
    contactUs: ConfigType['contactUs'] = {
        title: {
            'en': 'Contact us',
            'zh-tw': '聯繫我們',
            'zh-cn': '联系我们',
        },
        details: {
            'en': 'I BUILD CONCEPTS (SYSTEM) PTY LTD\nUNIT 26, 374-378 LYGON STREET, BRUNSWICK EAST VIC 3057\nE-mail:  joey@i-b-c.au',
            'zh-tw': 'I BUILD CONCEPTS (SYSTEM) PTY LTD\nUNIT 26, 374-378 LYGON STREET, BRUNSWICK EAST VIC 3057\nE-mail:  joey@i-b-c.au',
            'zh-cn': 'I BUILD CONCEPTS (SYSTEM) PTY LTD\nUNIT 26, 374-378 LYGON STREET, BRUNSWICK EAST VIC 3057\nE-mail:  joey@i-b-c.au',
        }
    }
    guides: Guide[] = [
    ]

    areaId = "8bed28819da8411a91f7b96ddc3ab5e6"
    api = 'https://api.shop.i-b-c.au'
    type: "dev" | "prod" = 'dev'
    payments = {
        stripe: {
            apiKey: 'pk_live_O9hKj87mgupwp5mYUQzUc2aU'
        }
    }
    logoUrl = ''

    //首次登入首頁時，觸發提示
    servicePrompt = {
        title: {
            'en': '',
            'zh-tw': '',
            'zh-cn': '',
        },
        img: {
            'en': '',
            'zh-cn': '',
            'zh-tw': '',
        },
        details: {
            'en': '',
            'zh-tw': '',
            'zh-cn': '',
        }
    }
}


const config = new ConfigDev();
(window as any)['getConfig'] = () => config
export default config


