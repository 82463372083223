import ActionType from './ActionType'
import tool from '../tool'
import { MyThunkDispatch } from './action-interface'
import api, { GetAreaModuleListParameters, GetAreaModuleListResult, GetAreaListParameters, GetAreaModuleListRes, GetAreaListRes, GetAreaKioskParameters, GetAreaKioskRes, GetAreaRes, GetAreaModuleRes } from '../api'

const initDataAction = () => {
    return {
        type: ActionType.areaType.INIT_DATA,
    }
}

const upDateAction = (data: { listData: any }) => {
    return {
        type: ActionType.areaType.UP_DATE,
        ...data
    }
}
const upDateModuleAction = (data: { moduleData: any }) => {
    return {
        type: ActionType.areaType.UP_DATE_MODULE,
        ...data
    }
}


const upKioskDataAction = (data: { kioskData: any }) => {
    return {
        type: ActionType.areaType.UP_KIOSK_DATA, ...data
    }
}

export interface ActionAreaRes {
    listData: GetAreaRes[],
    moduleData: GetAreaModuleRes[],
    kioskData: {
        [x: string]: GetAreaKioskRes
    },
}

/**
 * 获取area列表
 */
export const getAreaList = (params: GetAreaListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getAreaList({ type: 'COMMUNITY', count: 10000, ...params })
        if (res.kind === 'ok') {
            dispatch(upDateAction({ listData: res.data.data }))
        }
        return res
    }
}

/**
 * 获取功能模块
 */
export const getAreaModuleList = (params: GetAreaModuleListParameters) => {
    return async (dispatch: MyThunkDispatch): Promise<GetAreaModuleListResult> => {
        const res = await api.getAreaModuleList(params)
        if (res.kind === 'ok') {
            dispatch(upDateModuleAction({ moduleData: res.data.data }))
        }
        return res
    }
}

/**
 * 获取自提柜数据
 */
export const getKioskData = (params: GetAreaKioskParameters) => {
    const { kioskData }: ActionAreaRes = tool.store.getState().area
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getAreaKiosk(params)
        if (res.kind === 'ok') {
            kioskData[params.areaKioskId] = res.data
            dispatch(upKioskDataAction({ kioskData }))
        }
        return res
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => dispatch(initDataAction())
}





