import React from 'react';

import *as Ons from 'react-onsenui'
import { Boxe, GetUserRes } from '../../../api/api-res-type';
import tool from '../../../tool';
import moment from 'moment'
import boxePng from '../../../imgs/boxe.png'
import ModalQr from '../../../components/Modal/ModalQr';


interface PageState {
    qrModal: {
        show: boolean
        key: string
    }

}

export interface Props {
    boxe: Boxe
    style?: React.CSSProperties
    user: GetUserRes
}

type PageProps = Props

class JJ_ShopOrderBoxItem extends React.Component<PageProps, PageState> {


    componentWillUnmount() {
    }

    constructor(props) {
        super(props);
        this.state = {
            qrModal: {
                show: false,
                key: `${Date.now()}`
            }
        }
    }


    _getBoxeStatusText = (status: Boxe['status']) => {
        /**
         * PENDING: 未整理好貨品, 默認
         * DELIVERED: 已送到自提櫃, 等待取貨
         * COMPLETED: 已取貨
         * RECALLED: 沒取貨, 已過期
         */
        switch (status) {

            case 'PENDING': {
                return tool.intl.formatMessage({ id: 'boxeStatus[PENDING]' })
            }
            case 'DELIVERED': {
                return tool.intl.formatMessage({ id: 'boxeStatus[DELIVERED]' })
            }
            case 'COMPLETED': {
                return tool.intl.formatMessage({ id: 'boxeStatus[COMPLETED]' })
            }
            case 'RECALLED': {
                return tool.intl.formatMessage({ id: 'boxeStatus[RECALLED]' })
            }
        }

    }


    /**
     * 是否显示取货最后30分钟时间
     */
    _isShowPickupEnd = (boxe: Boxe) => {
        return Boolean(boxe.pickupEndAt && moment(boxe.pickupEndAt).isBefore(moment().add(30, 'minutes')))
    }


    _modalsView = () => {
        return (
            <ModalQr
                key={`modalQr_${this.state.qrModal.key}`}
                show={this.state.qrModal.show}
                onClose={() => this.setState({ qrModal: { ...this.state.qrModal, show: false } })}
                qrCode={`PICKUP,${this.props.user.id},${this.props.boxe.id}`}
                title={tool.intl.formatMessage({ id: 'JJ_ShopOrderBoxItem[modalQrTitle]' })}
                info={tool.intl.formatMessage({ id: 'JJ_ShopOrderBoxItem[contentViewRightViewButtonDescription]' })}
            />
        )
    }

    render() {

        return (
            <div
                id='JJ_ShopOrderBoxItem'
                style={this.props.style}
            >
                <div className='boxeStatus'>{this._getBoxeStatusText(this.props.boxe.status)}</div>
                <div
                    className='contentView'
                >
                    <div
                        className='contentViewLeftView'
                    >
                        <img
                            className='img'
                            src={boxePng}
                        />
                        <span className='pickupSlot'>{this.props.boxe.pickupSlot}</span>
                    </div>

                    <div
                        className='contentViewRightView'
                    >
                        <span
                            className='contentViewRightViewButtonDescription'
                        >{tool.intl.formatMessage({ id: 'JJ_ShopOrderBoxItem[contentViewRightViewButtonDescription]' })}</span>
                        <Ons.Button
                            className='contentViewRightViewButton'
                            modifier={'light'}
                            disabled={
                                !(
                                    this.props.boxe.status === 'DELIVERED' ||
                                    this._isShowPickupEnd(this.props.boxe)
                                )
                            }
                            onClick={() => {
                                this.setState({ qrModal: { ...this.state.qrModal, show: true } })
                                console.log(`PICKUP,${this.props.user.id},${this.props.boxe.id}`)
                            }}
                        >
                            {tool.intl.formatMessage({ id: 'JJ_ShopOrderBoxItem[contentViewRightViewButton]' })}
                        </Ons.Button>
                    </div>
                </div>
                {
                    this._isShowPickupEnd(this.props.boxe) &&
                    (
                        <span
                            className='pickupEndAtDescription'
                        >{tool.intl.formatMessage({ id: 'JJ_ShopOrderBoxItem[pickupEndAtDescription]' })}</span>
                    )
                }
                {this._modalsView()}
            </div>
        )
    }
}


export default JJ_ShopOrderBoxItem
