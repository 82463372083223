
import { ApiConfig, ApiConfigType } from "./api-config"
import * as Types from "./api-type"
import * as Parameters from './api-parameters-type'
import * as Res from './api-res-type'
import { AxiosInstance } from 'axios'
import { createHttp } from './http';

export class Api {

  config: ApiConfigType
  http: AxiosInstance

  constructor(config: ApiConfigType = ApiConfig) {
    this.config = config
    this.http = createHttp(this.config)
  }


  async postAuthSessionRequestNew(params: Parameters.PostAuthSessionRequestNewParameters): Promise<Types.PostAuthSessionRequestNewResult> {
    try {
      const res = await this.http.post('/v1/auth/session/request-new', params)
      const data: Res.PostAuthSessionRequestNewRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postAuthSmsRequest(params: Parameters.PostAuthSmsRequestParameters): Promise<Types.PostAuthSmsRequestResult> {
    try {
      const res = await this.http.post('/v1/auth/sms/request', params)
      const data: Res.PostAuthSmsRequestRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postAuthSmsRequestNew(params: Parameters.PostAuthSmsRequestNewParameters): Promise<Types.PostAuthSmsRequestNewResult> {
    try {
      const res = await this.http.post('/v1/auth/sms/request-new', params)
      const data: Res.PostAuthSmsRequestRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }

  async postAuthSmsVerify(params: Parameters.PostAuthSmsVerifyParameters): Promise<Types.PostAuthSmsVerifyResult> {
    try {
      const res = await this.http.post('/v1/auth/sms/verify', params)
      const data: Res.PostAuthSmsVerifyRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postAuthOneTimeVerify(params: Parameters.PostAuthOneTimeVerifyParameters): Promise<Types.PostAuthOneTimeVerifyResult> {
    try {
      const res = await this.http.post('/v1/auth/one-time/verify', params)
      const data: Res.PostAuthOneTimeVerifyRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getAreaModuleList(params: Parameters.GetAreaModuleListParameters): Promise<Types.GetAreaModuleListResult> {
    try {
      const res = await this.http.get('/v1/area-module', { params })
      const data: Res.GetAreaModuleListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getAreaModule(params: Parameters.GetAreaModuleParameters): Promise<Types.GetAreaModuleResult> {
    try {
      const res = await this.http.get(`/v1/area-module/${params.areaModuleId}`, { params })
      const data: Res.GetAreaModuleRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async deleteAuth(params?: Parameters.DeleteAuthParameters): Promise<Types.DeleteAuthResult> {
    try {
      const res = await this.http.delete(`/v1/auth`)
      const data: Res.DeleteAuthRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopCartCampaignTagList(params: Parameters.GetShopCartCampaignTagListParameters): Promise<Types.GetShopCartCampaignTagListResult> {
    try {
      const res = await this.http.get(`/v1/shop/campaign-tag`, { params })
      const data: Res.GetShopCartCampaignTagListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopCartCampaignTag(params: Parameters.GetShopCartCampaignTagParameters): Promise<Types.GetShopCartCampaignTagResult> {
    try {
      const res = await this.http.get(`/v1/shop/campaign-tag/${params.id}`, { params })
      const data: Res.GetShopCartCampaignTagRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }

  async getShopCartCampaignPackageList(params: Parameters.GetShopCartCampaignPackageListParameters): Promise<Types.GetShopCartCampaignPackageListResult> {
    try {
      const res = await this.http.get(`/v1/shop/cart-campaign-package`, { params })
      const data: Res.GetShopCartCampaignPackageListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopCartCampaignPackage(params: Parameters.GetShopCartCampaignPackageParameters): Promise<Types.GetShopCartCampaignPackageResult> {
    try {
      const res = await this.http.get(`/v1/shop/cart-campaign-package/${params.id}`, { params })
      const data: Res.GetShopCartCampaignPackageRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getUser(params: Parameters.GetUserParameters): Promise<Types.GetUserResult> {
    try {
      const res = await this.http.get(`/v1/user/${params.userId}`)
      const data: Res.GetUserRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postUser(params: Parameters.PostUserParameters): Promise<Types.PostUserResult> {
    try {
      const res = await this.http.post(`/v1/user`, params)
      const data: Res.PostUserRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getUserSettingList(params?: Parameters.GetUserSettingListParameters): Promise<Types.GetUserSettingListResult> {
    try {
      const res = await this.http.get(`/v1/user-setting`, { params })
      const data: Res.GetUserSettingListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async putUserSetting(params: Parameters.PutUserSettingParameters): Promise<Types.PutUserSettingResult> {
    try {
      const res = await this.http.put(`/v1/user-setting/${params.key}`, params)
      const data: Res.PutUserSettingRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getAreaList(params: Parameters.GetAreaListParameters): Promise<Types.GetAreaListResult> {
    try {
      const res = await this.http.get(`/v1/area`, { params })
      const data: Res.GetAreaListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getArea(params: Parameters.GetAreaParameters): Promise<Types.GetAreaResult> {
    try {
      const res = await this.http.get(`/v1/area/${params.areaId}`, { params })
      const data: Res.GetAreaRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getAreaKiosk(params: Parameters.GetAreaKioskParameters): Promise<Types.GetAreaKioskResult> {
    try {
      const res = await this.http.get(`/v1/area-kiosk/${params.areaKioskId}`, { params })
      const data: Res.GetAreaKioskRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postAuthSessionVerify(params: Parameters.PostAuthSessionVerifyParameters): Promise<Types.PostAuthSessionVerifyResult> {
    try {
      const res = await this.http.post(`/v1/auth/session/verify`, params)
      const data: Res.PostAuthSessionVerifyRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getPaymentList(params: Parameters.GetPaymentListParameters): Promise<Types.GetPaymentListResult> {
    try {
      const res = await this.http.get(`/v1/payment`, { params })
      const data: Res.GetPaymentListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getPayment(params: Parameters.GetPaymentParameters): Promise<Types.GetPaymentResult> {
    try {
      const res = await this.http.get(`/v1/payment/${params.paymentId}`, { params })
      const data: Res.GetPaymentRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopCampaignList(params: Parameters.GetShopCampaignListParameters): Promise<Types.GetShopCampaignListResult> {
    try {
      const res = await this.http.get(`/v1/shop/campaign`, { params })
      const data: Res.GetShopCampaignListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopCampaign(params: Parameters.GetShopCampaignParameters): Promise<Types.GetShopCampaignResult> {
    try {
      const res = await this.http.get(`/v1/shop/campaign/${params.campaignId}`, { params })
      const data: Res.GetShopCampaignRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postShopCartCampaignPackage(params: Parameters.PostShopCartCampaignPackageParameters): Promise<Types.PostShopCartCampaignPackageResult> {
    try {
      const res = await this.http.post(`/v1/shop/cart-campaign-package`, params)
      const data: Res.PostShopCartCampaignPackageRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async putShopCartCampaignPackage(params: Parameters.PutShopCartCampaignPackageParameters): Promise<Types.PutShopCartCampaignPackageResult> {
    try {
      const res = await this.http.put(`/v1/shop/cart-campaign-package/${params.id}`, params)
      const data: Res.PutShopCartCampaignPackageRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async deleteShopCartCampaignPackage(params: Parameters.DeleteShopCartCampaignPackageParameters): Promise<Types.DeleteShopCartCampaignPackageResult> {
    try {
      const res = await this.http.delete(`/v1/shop/cart-campaign-package/${params.id}`)
      const data: Res.DeleteShopCartCampaignPackageRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopOrderList(params: Parameters.GetShopOrderListParameters): Promise<Types.GetShopOrderListResult> {
    try {
      const res = await this.http.get(`/v1/shop/order`, { params })
      const data: Res.GetShopOrderListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopOrder(params: Parameters.GetShopOrderParameters): Promise<Types.GetShopOrderResult> {
    try {
      const res = await this.http.get(`/v1/shop/order/${params.orderId}`, { params })
      const data: Res.GetShopOrderRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postShopOrder(params: Parameters.PostShopOrderParameters): Promise<Types.PostShopOrderResult> {
    try {
      const res = await this.http.post(`/v1/shop/order`, params)
      const data: Res.PostShopOrderRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async putShopOrder(params: Parameters.PutShopOrderParameters): Promise<Types.PutShopOrderResult> {
    try {
      const res = await this.http.put(`/v1/shop/order/${params.orderId}`, params)
      const data: Res.PutShopOrderRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async deleteShopOrder(params: Parameters.DeleteShopOrderParameters): Promise<Types.DeleteShopOrderResult> {
    try {
      const res = await this.http.delete(`/v1/shop/order/${params.orderId}`)
      const data: Res.DeleteShopOrderRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopOrderByHumanId(params: Parameters.GetShopOrderByHumanIdParameters): Promise<Types.GetShopOrderByHumanIdResult> {
    try {
      const res = await this.http.get(`/v1/shop/order-by-human-id/${params.orderHumanId}`, { params })
      const data: Res.GetShopOrderByHumanIdRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  /**
   * 從購物車建立訂單, 選擇部份已添加的商品及數量
   */
  async postShopOrderFromCart(params: Parameters.PostShopOrderFromCartParameters): Promise<Types.PostShopOrderFromCartResult> {
    try {
      const res = await this.http.post(`/v1/shop/order-from-cart`, params)
      const data: Res.PostShopOrderFromCartRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postShopOrderCancel(params: Parameters.PostShopOrderCancelParameters): Promise<Types.PostShopOrderCancelResult> {
    try {
      const res = await this.http.post(`/v1/shop/order-cancel`, params)
      const data: Res.PostShopOrderCancelRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postShopOrderPayment(params: Parameters.PostShopOrderPaymentParameters): Promise<Types.PostShopOrderPaymentResult> {
    try {
      const res = await this.http.post(`/v1/shop/order-payment`, params)
      const data: Res.PostShopOrderPaymentRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getUserShopDeliveryAddressList(params?: Parameters.GetUserShopDeliveryAddressListParameters): Promise<Types.GetUserShopDeliveryAddressListResult> {
    try {
      const res = await this.http.get(`/v1/user-shop-delivery-address`, { params })
      const data: Res.GetUserShopDeliveryAddressListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getUserShopDeliveryAddress(params: Parameters.GetUserShopDeliveryAddressParameters): Promise<Types.GetUserShopDeliveryAddressResult> {
    try {
      const res = await this.http.get(`/v1/user-shop-delivery-address/${params.deliveryAddressId}`, { params })
      const data: Res.GetUserShopDeliveryAddressRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postUserShopDeliveryAddress(params: Parameters.PostUserShopDeliveryAddressParameters): Promise<Types.PostUserShopDeliveryAddressResult> {
    try {
      const res = await this.http.post(`/v1/user-shop-delivery-address`, params)
      const data: Res.PostUserShopDeliveryAddressRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async putUserShopDeliveryAddress(params: Parameters.PutUserShopDeliveryAddressParameters): Promise<Types.PutUserShopDeliveryAddressResult> {
    try {
      const res = await this.http.put(`/v1/user-shop-delivery-address/${params.deliveryAddressId}`, params)
      const data: Res.PutUserShopDeliveryAddressRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async deleteUserShopDeliveryAddress(params: Parameters.DeleteUserShopDeliveryAddressParameters): Promise<Types.DeleteUserShopDeliveryAddressResult> {
    try {
      const res = await this.http.delete(`/v1/user-shop-delivery-address/${params.deliveryAddressId}`)
      const data: Res.DeleteUserShopDeliveryAddressRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getUserShopDeliveryRecipientList(params?: Parameters.GetUserShopDeliveryRecipientListParameters): Promise<Types.GetUserShopDeliveryRecipientListResult> {
    try {
      const res = await this.http.get(`/v1/user-shop-delivery-recipient`, { params })
      const data: Res.GetUserShopDeliveryRecipientListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getUserShopDeliveryRecipient(params: Parameters.GetUserShopDeliveryRecipientParameters): Promise<Types.GetUserShopDeliveryRecipientResult> {
    try {
      const res = await this.http.get(`/v1/user-shop-delivery-recipient/${params.deliveryRecipientId}`, { params })
      const data: Res.GetUserShopDeliveryRecipientRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async postUserShopDeliveryRecipient(params: Parameters.PostUserShopDeliveryRecipientParameters): Promise<Types.PostUserShopDeliveryRecipientResult> {
    try {
      const res = await this.http.post(`/v1/user-shop-delivery-recipient`, params)
      const data: Res.PostUserShopDeliveryRecipientRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async putUserShopDeliveryRecipient(params: Parameters.PutUserShopDeliveryRecipientParameters): Promise<Types.PutUserShopDeliveryRecipientResult> {
    try {
      const res = await this.http.put(`/v1/user-shop-delivery-recipient/${params.deliveryRecipientId}`, params)
      const data: Res.PutUserShopDeliveryRecipientRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async deleteUserShopDeliveryRecipient(params: Parameters.DeleteUserShopDeliveryRecipientParameters): Promise<Types.DeleteUserShopDeliveryRecipientResult> {
    try {
      const res = await this.http.delete(`/v1/user-shop-delivery-recipient/${params.deliveryRecipientId}`)
      const data: Res.DeleteUserShopDeliveryRecipientRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopDelivery(params: Parameters.GetShopDeliveryParameters): Promise<Types.GetShopDeliveryResult> {
    try {
      const res = await this.http.get(`/v1/shop/delivery/${params.deliveryId}`, { params })
      const data: Res.GetShopDeliveryRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getShopDeliveryList(params: Parameters.GetShopDeliveryListParameters): Promise<Types.GetShopDeliveryListResult> {
    try {
      const res = await this.http.get(`/v1/shop/delivery`, { params })
      const data: Res.GetShopDeliveryListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }

  async getUserCouponList(params: Parameters.GetUserCouponListParameters): Promise<Types.GetUserCouponListResult> {
    try {
      const res = await this.http.get(`/v1/user-coupon`, { params })
      const data: Res.GetUserCouponListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  async getUserCoupon(params: Parameters.GetUserCouponParameters): Promise<Types.GetUserCouponResult> {
    try {
      const res = await this.http.get(`/v1/user-coupon/${params.id}`, { params })
      const data: Res.GetUserCouponRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  /**
   * 此接口會更新 UserStatus 的最後時間
   */
  async postUserCouponFeed(): Promise<Types.PostUserCouponFeedResult> {
    try {
      const res = await this.http.post(`/v1/user-coupon-feed`)
      const data: Res.PostUserCouponFeedRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }

  /**
   * 此接口會查詢所有可用的優惠卷
   */
  async postUserCouponUsable(params: Parameters.PostUserCouponUsableParameters): Promise<Types.PostUserCouponUsableResult> {
    try {
      const res = await this.http.post(`/v1/user-coupon-usable`, params)
      const data: Res.PostUserCouponUsableRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  /**
   * 此接口可驗証指定用戶優惠卷是否可用
   */
  async postShopOrderCouponVerifiy(params: Parameters.PostShopOrderCouponVerifiyParameters): Promise<Types.PostShopOrderCouponVerifiyResult> {
    try {
      const res = await this.http.post(`/v1/shop/order-coupon-verifiy`, params)
      const data: Res.PostShopOrderCouponVerifiyRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }
  /**
   * 廣告
   */
  async getAdvertList(params: Parameters.GetAdvertListParameters): Promise<Types.GetAdvertListResult> {
    try {
      const res = await this.http.get(`/v1/advert`, { params })
      const data: Res.GetAdvertListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }

  /**
   * 钱包
   */
  async getWalletList(params: Parameters.GetWalletListParameters): Promise<Types.GetWalletListResult> {
    try {
      const res = await this.http.get(`/v1/wallet`, { params })
      const data: Res.GetWalletListRes = res.data
      return { kind: 'ok', data }
    } catch (response) {

      return { kind: response ? response.data || '' : '' }
    }
  }


}

