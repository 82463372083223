import React from 'react'
import { injectIntl, InjectedIntlProps } from "react-intl";
import queryString from 'query-string';
import *as Ons from 'react-onsenui'
import logo from '../../imgs/logo.png'
import tool from '../../tool';
import config from '../../config/config';



interface PageState {

}

export interface Props {
    title?: string
    showLeftIcon: boolean
    leftIcon?: React.ReactNode
    rightIcon?: React.ReactNode
}

type PageProps = Props & InjectedIntlProps 

class JJ_Nav extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {
        }
    }

    static defaultProps = {
        title: '',
    };


    componentDidMount() {

    }

    _actions = ({ type = '', data = {} }) => {
        switch (type) {
            case 'cn': {

            }
                break
            case 'tw': {

            }
                break
            case 'en': {

            }
                break
            default: {

            }
        }

    }



    _leftAction = () => {
        tool.navigator.popPage()
    }

    render() {
        const leftButton = this.props.showLeftIcon
            ? <Ons.BackButton onClick={() => this._leftAction()} />
            : ''

        // check webview
        const urlParams = queryString.parse(location.search);
        const isWebview = urlParams.view === 'webview';

        return (
            <Ons.Toolbar
                id='JJ_Nav'
                modifier='transparent'
            >
                <div className='left'>{leftButton}</div>
                <div className="center">
                    <div className="titleLogo"><img src={logo} /></div>
                </div>
                <div className="right">{this.props.rightIcon || ''}</div>
            </Ons.Toolbar>
        )

    }
}



export default injectIntl(JJ_Nav)


