import React from 'react'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import JJ_UserDeliveryList from './PageItem/JJ_UserDeliveryList'
import tool from '../../tool';
import { GetShopOrderRes, ShopDeliveryType } from '../../api';

interface PageState {
    selectData: {
        type: ShopDeliveryType
        deliveryId: string
        deliveryAddressId: string
    }

}


export interface Props {
    onSubmit(params: {
        type: ShopDeliveryType

        deliveryId: string

        deliveryAddressId: string
    }): void
    order: GetShopOrderRes
}


type PageProps = Props


class JJ_UserDelivery extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {
            selectData: {
                deliveryId: props.order.deliveryId || '',
                deliveryAddressId: props.order.deliveryAddressId || '',
                type: 'DIRECT'
            },

        }
    }




    componentDidMount() {


    }

    _btnDisabled = () => {
        const { type, deliveryId, deliveryAddressId } = this.state.selectData
        switch (type) {

            case 'SELF_PICKUP'://自取
            case 'DSP_LOCKER': {//自提柜
                return Boolean(deliveryId)
            }
            case 'DIRECT': {//上门
                return Boolean(deliveryId) && Boolean(deliveryAddressId)
            }
            default:
                return Boolean(deliveryId)

        }
    }

    render() {

        const nav = {

            showLeftIcon: true,
            title: tool.intl.formatMessage({ id: 'shopOrderDeliveryTitle' }),

        }
        return (
            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>
                <div
                    id='JJ_UserDelivery'
                >
                    <div className="scroll">
                        <JJ_UserDeliveryList
                            onSelect={selectData => {
                                this.setState({ selectData })
                            }}
                            moduleId={this.props.order.moduleId}
                            amount={this.props.order.price}
                            deliveryId={this.state.selectData.deliveryId}
                            deliveryAddressId={this.state.selectData.deliveryAddressId}
                        />
                    </div>
                    <Ons.Button
                        className="footView"
                        disabled={!this._btnDisabled()}
                        onClick={() => {
                            tool.navigator.popPage()
                            this.props.onSubmit(this.state.selectData)
                        }}
                    >
                        {tool.intl.formatMessage({ id: '提交' })}
                    </Ons.Button>
                </div>
            </Ons.Page>

        )
    }
}
export default JJ_UserDelivery




