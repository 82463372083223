import React, { CSSProperties } from 'react'
import { Carousel } from 'antd-mobile';
import { GetAdvertRes } from '../../api';


interface PageState {

}

export type Props = {
    selectedIndex?: number//手动设置当前显示的索引
    autoplay?: boolean//是否自动切换
    infinite?: boolean//是否循环播放
    dots?: boolean//是否显示面板指示点
    autoplayInterval?: number//自动切换的时间间隔
    adverts: GetAdvertRes[]
    style?: CSSProperties
    onClick?(e: GetAdvertRes): void
}

type PageProps = Props

class BaseBannerAdvert extends React.Component<PageProps, PageState> {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    render() {
        const meta = this.props.adverts[0].asset.meta
        const width = window.innerWidth
        const height = width / meta.width * meta.height


        return (
            <div
                id='BaseBannerAdvert'
                style={this.props.style}
            >
                <Carousel
                    className={'carousel'}
                    style={{
                        height,
                        width,
                        backgroundColor: 'white',
                    }}
                    autoplay={this.props.autoplay}
                    infinite={this.props.infinite}
                    dots={this.props.dots}
                    selectedIndex={this.props.selectedIndex}
                    autoplayInterval={this.props.autoplayInterval}
                >
                    {
                        this.props.adverts.map(advert => {
                            return (
                                <div
                                    key={advert.id}
                                    className='advert'
                                    onClick={() => {
                                        this.props.onClick && this.props.onClick(advert)
                                    }}
                                >
                                    <img
                                        src={advert.asset.url}
                                        className='thumbnail'
                                        style={{
                                            height,
                                            width,
                                            overflow: 'hidden',
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
        );
    }

}

export default BaseBannerAdvert
