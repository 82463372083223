import axios from 'axios'
import { ApiConfigType } from "./api-config"
import tool from '../tool'
import *as ActionUser from '../actions/ActionUser'
import { confirm } from '../tool/ToolDialog'
import { MdFormatTextdirectionRToL } from 'react-icons/md'
export const createHttp = (config: ApiConfigType) => {
    const http = axios.create({
        baseURL: config.url,
        validateStatus: status => status === 200,
        // validateStatus: function (status) {
        //     return true
        // },
        withCredentials: true, // 默认的
        timeout: 30000
    })

    http.interceptors.request.use(config => {
        return config
    })

    http.interceptors.response.use(response => {

        console.log(`${response.config.method}====>${response.config.url}`, response)
        return response
    }, async (error) => {
        console.log(`${error.message}`)
        if (typeof (error.message) === 'string' && (error.message as string).indexOf('timeout') !== -1) {
            tool.message.fail({ content: 'Network Error. Please retry' })
        }
        switch (error.message) {
            case 'Network Error': {
                tool.message.fail({ content: 'Network Error. Please retry' })
                return Promise.reject(error.response)
            }
        }
        console.error(`${error.response.config.method}====>${error.response.config.url}`, error.response)
        const status = error.response.status
        if (status === 403) {
            //退出登录
            tool.store.dispatch(ActionUser.authOut())
        }
        return Promise.reject(error.response)
    })
    return http
}


