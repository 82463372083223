import React from 'react';
import tool from '../../../tool';
import { GetShopOrderRes, GetShopDeliveryRes } from '../../../api';

interface PageState {

}

export interface Props {
    order: GetShopOrderRes
    delivery: GetShopDeliveryRes
}

type PageProps = Props



class JJ_ShopOrderLogisticsDisplay extends React.Component<PageProps, PageState> {



    componentWillUnmount() {

    }

    constructor(props: PageProps) {
        super(props);
        this.state = {

        }
    }

    _getIdIndex = () => {
        let idIndex = 0
        this._initListData().forEach((item, i) => {
            if (item.id === this.props.order.status) {
                idIndex = i
            }
        })
        return idIndex
    }

    _initListData = () => {

        switch (this.props.delivery.type) {
            case 'SELF_PICKUP': {//前往仓库自取
                return [
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusPendingDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusPending' }), id: 'PENDING', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusPaidDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusPaid' }), id: 'PAID', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusDeliveredSelfPickupDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusDelivered' }), id: 'DELIVERED', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusCompletedDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusCompleted' }), id: 'COMPLETED', },
                ]
            }
            case 'DIRECT': {//送货上门
                return [
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusPendingDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusPending' }), id: 'PENDING', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusPaidDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusPaidDirect' }), id: 'PAID', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusDeliveredDirectDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusDelivered' }), id: 'DELIVERED', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusCompletedDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusCompleted' }), id: 'COMPLETED', },
                ]
            }
            case 'DSP_LOCKER': {//自提柜
                return [
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusPendingDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusPending' }), id: 'PENDING', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusPaidSpLockerDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusPaid' }), id: 'PAID', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusDeliveredSpLockerDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusDelivered' }), id: 'DELIVERED', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusCompletedDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusCompleted' }), id: 'COMPLETED', },
                ]
            }
            case 'SP_LOCKER': {//新自提柜
                return [
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusPendingDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusPending' }), id: 'PENDING', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusPaidSpLockerDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusPaid' }), id: 'PAID', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusDeliveredSpLockerDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusDelivered' }), id: 'DELIVERED', },
                    { describe: tool.intl.formatMessage({ id: 'shopOrderStatusCompletedDescription' }), title: tool.intl.formatMessage({ id: 'shopOrderStatusCompleted' }), id: 'COMPLETED', },
                ]
            }
            default: return []
        }

    }

    render() {
        const idIndex = this._getIdIndex()
        return (
            <div
                id='JJ_ShopOrderLogisticsDisplay'
            >
                {this._initListData().map((item, index) => {
                    return (
                        <div
                            key={item.id}
                            className='itemBox'
                        >
                            <div className='textBox'>
                                <div className={index <= idIndex ? 'highlightDot' : 'disabledDot'} />
                                <div className={index === idIndex ? 'highlightText' : 'disabledText'}>
                                    <span className={'title'}>{item.title}</span>
                                    <span className={'describe'}>{item.describe}</span>
                                </div>
                            </div>
                            {index !== this._initListData().length - 1 && <div className={index < idIndex ? 'highlightLine' : 'disabledLine'} />}
                        </div>
                    )
                })}
            </div>

        )

    }
}



export default JJ_ShopOrderLogisticsDisplay
