import React from 'react';
import moment from 'moment';
import JJ_ShopOrderCampaignPackage from './JJ_ShopOrderCampaignPackage'
import { GetShopOrderRes, GetShopCampaignRes } from '../../../api';
import tool from '../../../tool';

interface PageState {


}

export interface Props {
    onDetailsAction?(props: PageProps): void
    campaign?: GetShopCampaignRes
}

type PageProps = Props & GetShopOrderRes



class JJ_ShopOrderListItem extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
        }
    }



    _getOrderStatusText = (order: GetShopOrderRes, campaign: GetShopCampaignRes) => {


        switch (campaign.type) {
            case 'FLASH':
            case 'NORMAL': {
                switch (order.status) {
                    case 'PENDING': {

                        return tool.intl.formatMessage({ id: 'orderStatus[PENDING]' })

                    }
                    case 'PREPAID': {

                        return tool.intl.formatMessage({ id: 'orderStatus[PREPAID]' })

                    }
                    case 'PAID': {
                        return tool.intl.formatMessage({ id: 'orderStatus[PAID]' })

                    }
                    case 'DELIVERED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[DELIVERED]' })

                    }
                    case 'EXPIRED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[EXPIRED]' })

                    }
                    case 'CANCELED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[CANCELED]' })

                    }
                    case 'COMPLETED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[COMPLETED]' })

                    }
                    case 'RECALLED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[RECALLED]' })

                    }
                    case 'REFUNDED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[REFUNDED]' })

                    }
                    default: return '???????'

                }
            }
            case 'GROUP': {
                switch (order.status) {
                    case 'PENDING': {

                        return tool.intl.formatMessage({ id: 'orderStatus[PENDING]' })

                    }
                    case 'PREPAID': {

                        if (campaign.status === 'ONGOING') {
                            //進行中
                            //等待成团结束
                            return tool.intl.formatMessage({ id: 'orderStatus[PREPAID_ONGOING]' })

                        }

                        if (campaign.status === 'CONFIRMED') {
                            //已成團
                            //成团成功，等待支付余额
                            return tool.intl.formatMessage({ id: 'orderStatus[PREPAID_CONFIRMED]' })
                        }

                        return tool.intl.formatMessage({ id: 'orderStatus[PREPAID]' })

                    }
                    case 'PAID': {

                        if (campaign.status === 'ONGOING') {
                            //已经支付
                            //等待成团结束

                            return tool.intl.formatMessage({ id: 'orderStatus[PAID_ONGOING]' })

                        }

                        if (campaign.status === 'CONFIRMED') {
                            //已经支付
                            //等待发货
                            return tool.intl.formatMessage({ id: 'orderStatus[PAID_CONFIRMED]' })
                        }

                        return tool.intl.formatMessage({ id: 'orderStatus[PAID]' })

                    }
                    case 'DELIVERED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[DELIVERED]' })

                    }
                    case 'EXPIRED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[EXPIRED]' })

                    }
                    case 'CANCELED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[CANCELED]' })

                    }
                    case 'COMPLETED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[COMPLETED]' })

                    }
                    case 'RECALLED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[RECALLED]' })

                    }
                    case 'REFUNDED': {
                        return tool.intl.formatMessage({ id: 'orderStatus[REFUNDED]' })

                    }
                    default: return '???????'

                }
            }
        }



    }

    _getTotalAmaount = () => {
        const { campaignPackages } = this.props
        const totalAmaount = campaignPackages.reduce((p, c) => p += c.amount, 0)
        return totalAmaount
    }

    _titleView = () => {
        return (
            <div className='titleView'>
                <div className='humanId'>{tool.intl.formatMessage({ id: 'JJ_ShopOrderListItem[humanIdTitle]' }, {
                  humanId: this.props.humanId,
                })}</div>
                <div className='titleInfoView'>
                    <div className='status'>{this.props.campaign && this._getOrderStatusText(this.props, this.props.campaign)}</div>
                    <div className='createdAt'>{moment(this.props.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                </div>
            </div>
        )
    }

    _campaignPackages = () => {
        const { campaignPackages } = this.props
        return (
            <div className='campaignPackages'>
                {
                    campaignPackages.map((item, index) => {
                        const orderCampaignPackage = {
                            id: item.id,
                            campaignId: item.campaignId,
                            campaignPackageId: item.campaignPackageId,
                            amount: item.amount,
                            price: item.price,
                            priceOriginal: item.priceOriginal,
                            key: item.id,
                            style: {
                                marginBottom: index === campaignPackages.length - 1
                                    ? 0
                                    : 5
                            },
                        }
                        return (
                            <JJ_ShopOrderCampaignPackage
                                {...orderCampaignPackage}
                            />
                        )
                    })
                }
            </div>
        )
    }

    _totalText = () => {
        const { price, shippingFee, additionalFee, discount } = this.props
        return (
            <div
                className='totalText'
            >
                <h1
                >
                    {tool.intl.formatMessage({ id: '总共商品' }, { number: this._getTotalAmaount() })} - {tool.intl.formatMessage({ id: 'shopOrderTotalAmount' })} ({tool.intl.formatMessage({ id: 'shopOrderCalculatedAdditionalShippingAmount' })}):
                </h1>
                <h2><span style={{ fontSize: 10 }}>$</span>{tool.math.initPrice({ price: price + shippingFee + additionalFee - discount }).toFixed(2)}</h2>
            </div>
        )
    }


    render() {

        return (

            <div
                onClick={() => this.props.onDetailsAction && this.props.onDetailsAction(this.props)}
                id='JJ_ShopOrderListItem'
            >
                {this._titleView()}
                {this._campaignPackages()}
                {this._totalText()}

            </div>

        )

    }
}




export default JJ_ShopOrderListItem
