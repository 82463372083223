import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import JJ_Components from '../../../components/JJ_Components'
import JJ_ShopOrderListItem from './JJ_ShopOrderListItem'
import *as ActionOrder from '../../../actions/ActionOrder'
import { GetShopOrderRes, GetShopOrderListParameters, GetShopCampaignRes } from '../../../api'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import tool from '../../../tool'
import { ActionAreaRes } from '../../../actions/ActionArea'
import { ActionCampaignRes } from '../../../actions/ActionCampaign'


interface PageState {
    pageKey: string
}


export interface Props {

}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>




interface RootState {
    order: ActionOrder.ActionOrderRes
    area: ActionAreaRes
    campaign: ActionCampaignRes
}

const mapState = (state: RootState) => ({
    order: state.order,
    area: state.area,
    campaign: state.campaign,
})

const mapDispatch = {
    getShopOrderList: ActionOrder.getShopOrderList
}

export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_ShopOrderList extends React.Component<PageProps, PageState> {


    constructor(props: PageProps) {
        super(props);
        this.state = {
            pageKey: `${new Date()}`
        }

    }

    componentWillUnmount() {
        this.setState = () => { }
    }

    _initData = async (): Promise<void> => {
        try {

        } catch (error) {
            console.log(error)
        }
    }

    _getShopOrderList = async (params: GetShopOrderListParameters) => {
        const res = await this.props.getShopOrderList(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
        }
        throw new Error(res.kind)
    }


    _onRenderRow = (rowData: GetShopOrderRes) => {
        const moduleData = this.props.area.moduleData.find(item => item.type === 'SHOP')
        const order = this.props.order.list[rowData.id]
        return (
            <JJ_ShopOrderListItem
                key={order.id}
                {...order}
                campaign={this.props.campaign.list[rowData.campaignPackages[0].campaignId]}
                onDetailsAction={(props) => moduleData && tool.navigator.pushPage({
                    key: 'JJ_ShopOrderDetails', props: {
                        orderId: props.id,
                        moduleId: moduleData.id,
                        onCancel: (orderId) => {
                            this.setState({ pageKey: `${new Date()}` })
                        },
                        onDelete: (orderId) => {
                            this.setState({ pageKey: `${new Date()}` })
                        },
                    }
                })}
            />
        )
    }

    _view = () => {


        return (
            <div
                id='JJ_ShopOrderList'
            >
                <JJ_Components.baseListView
                    renderSeparator={(sectionID, rowID) => (<div style={{ height: 10 }} key={`${sectionID}${rowID}`} />)}
                    noDataViewText={`${tool.intl.formatMessage({ id: 'shopOrderListNoDateText' })}`}
                    renderRow={this._onRenderRow}
                    onLoadData={({ from, count }) => this._getShopOrderList({
                        from,
                        count,

                    })}

                />
            </div>
        )
    }

    render() {

        return (
            <JJ_Components.boxPage
                key={`${this.state.pageKey}pageKey`}
                loadData={this._initData}
            >
                {this._view()}
            </JJ_Components.boxPage>

        )
    }
}



export default connector(injectIntl(JJ_ShopOrderList))



