import React, { Component } from 'react';
import queryString from 'query-string';
import en_US from 'antd-mobile/lib/locale-provider/en_US';
import '../less/index.less';
import {
    LocaleProvider,
    Modal
} from 'antd-mobile';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import 'moment/locale/zh-cn';
import JJ_Loading from './JJ_Loading/JJ_Loading';
import *as Ons from 'react-onsenui'
import { getLangPack } from '../intl/utils';
import { getNavigator } from '../tool/ToolNavigator';
import tool from '../tool';
import api, { PutUserSettingParameters } from '../api';
import config from '../config/config';
import { ConnectedProps, connect } from 'react-redux';
import *as ActionUser from '../actions/ActionUser';
import JJ_Components from '../components/JJ_Components';
const zh_CN = undefined



export interface PageState {
    isOpen: boolean
    showAboutUS: boolean
}

export type PageProps = Props

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
}
export interface Page {

}


interface RootState {
    user: ActionUser.ActionUserRes

}

const mapState = (state: RootState) => ({
    userData: state.user,

})

const mapDispatch = {
    authOut: ActionUser.authOut
}

export const connector = connect(
    mapState,
    mapDispatch
)


class App extends Component<PageProps, PageState> {

    navigator?: Ons.Navigator

    constructor(props: Readonly<PageProps>) {
        super(props)
        this.state = {
            isOpen: false,
            showAboutUS: false,
        }
    }


    _setLangAndt = (lang: any) => {
        switch (lang) {

            case 'zh-cn':
                return zh_CN;

            case 'zh-tw':
                return zh_CN;

            case 'en':
                return en_US;

            default:
                return en_US;
        }
    }


    renderPage = (route: any, navigator?: Ons.Navigator | undefined) => {
        route.props = route.props || {};
        route.props.navigator = navigator;
        navigator && getNavigator(navigator)
        route.props.showMenu = this.show;
        return React.createElement(route.component, route.props);
    }

    _pushPage = ({ page, title, }: any) => {
        if (this.navigator) {
            this.hide();
            const currentPage = this.navigator.pages.slice(-1)[0] // --- or [this.navigator.pages.length - 1]
            if (currentPage.key !== page.name) {
                this.navigator.pushPage({
                    component: page,
                    props: { key: page.name, title }
                })
            }

        }
    }

    hide = () => {
        this.setState({ isOpen: false });
    }

    show = () => {
        this.setState({ isOpen: true });
    }

    _putUserSetting = async (params: PutUserSettingParameters) => {
        const res = await api.putUserSetting(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'INVALID_KEY': {

            }
                break
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _onPutUserSetting = async (params: PutUserSettingParameters) => {
        try {
            tool.message.loading({})
            await this._putUserSetting(params)
            tool.navigator.resetPage({ key: 'JJ_Loading' }, { animation: 'node' })
            tool.message.hide()

        } catch (error) {
            tool.message.hide()
            console.error(error)
        }

    }

    _modalsView = () => {
        return (
            <Modal
                visible={this.state.showAboutUS}
                onClose={() => this.setState({ showAboutUS: false })}
                transparent
                style={{
                    width: '90vw',
                }}
                title={config.contactUs.title[this.props.userData.lang]}
                animationType="fade"
                footer={[{
                    text: 'Ok', onPress: () => {
                        this.setState({ showAboutUS: false })
                    }
                }]}
            >
                <JJ_Components.baseBanner>
                    {config.guides.map((guide, index) => {
                        return (
                            <img
                                style={{
                                    width: '100%',
                                    height: '50vw',
                                }}
                                key={index}
                                src={guide.img[this.props.userData.lang]}
                            />
                        )
                    })}
                </JJ_Components.baseBanner>
                <div
                    style={{ textAlign: 'left',marginTop:10, whiteSpace: 'pre-wrap'}}
                    className={'column'}
                >
                    {config.contactUs.details[this.props.userData.lang]}
                </div>
            </Modal>
        )
    }

    _splitterSideView = () => {
        interface ListInterface {
            title: string
            list: ListItemInterface[]
        }

        interface ListItemInterface {
            title: string
            onAction?(listItem: ListItemInterface): void
            disabled?: boolean
        }

        // check webview
        const urlParams = queryString.parse(location.search);
        const isWebview = urlParams.view === 'webview';

        const listData: ListInterface[] = [
            ...(this.props.userData.class !== 'GUEST' ? [
                {
                    title: tool.intl.formatMessage({ id: 'appSideMenuMyItemsTitle' }),
                    list: [
                        {
                            title: tool.intl.formatMessage({ id: 'shopCampaignOrder' }),
                            onAction: (item) => {
                                this.hide()
                                tool.navigator.pushPage({ key: 'JJ_ShopOrder' })
                            }
                        },
                        {
                            title: tool.intl.formatMessage({ id: 'userCoupon[title]' }),
                            onAction: (item) => {
                                this.hide()
                                tool.navigator.pushPage({
                                    key: 'JJ_UserCoupon', props: {
                                        switchSelect: false,
                                        listProps: {
                                            type: 'getUserCouponList',
                                            params: {},
                                        },
                                        renderFooter: (<div>
                                            {tool.intl.formatMessage({ id: 'userCoupon[loadMoreEnd]' })}
                                        </div>),
                                    }
                                })
                            }
                        },
                    ],
                },
            ] : []),
            ...(!isWebview ? [
                {
                    title: tool.intl.formatMessage({ id: 'appSideMenuChangeLanguageTitle' }),
                    list: [
                        {
                            title: '简体中文',
                            onAction: (item) => {
                                this.hide()
                                this._onPutUserSetting({ key: 'lang', value: 'zh-cn' })
                            },
                            disabled: this.props.userData.lang === 'zh-cn'
                        },
                        {
                            title: '繁體中文',
                            onAction: (item) => {
                                this.hide()
                                this._onPutUserSetting({ key: 'lang', value: 'zh-tw' })
                            },
                            disabled: this.props.userData.lang === 'zh-tw'
                        },
                        {
                            title: 'English',
                            onAction: (item) => {
                                this.hide()
                                this._onPutUserSetting({ key: 'lang', value: 'en' })
                            },
                            disabled: this.props.userData.lang === 'en'
                        },
                    ],
                },
            ] : []),
            {
                title: tool.intl.formatMessage({ id: 'AppSplitterSideTitle[other]' }),
                list: [
                    {
                        title: tool.intl.formatMessage({ id: 'AppSlitterSideTitle[shopDeliverySpLocker]' }),
                        onAction: async (item) => {
                            this.hide()
                            tool.navigator.pushPage({ key: 'JJ_ShopDeliverySpLocker' })
                        },
                    },
                    ...(!isWebview ? [
                        {
                            title: config.contactUs.title[this.props.userData.lang],
                            onAction: (item) => {
                                this.setState({ showAboutUS: true })
                            },
                        },
                        {
                            title: tool.intl.formatMessage({ id: 'AppSplitterSideTitle[SignOut]' }),
                            onAction: async (item) => {
                                try {
                                    tool.message.loading({})
                                    await this.props.authOut()
                                    tool.message.hide()
                                    this.hide()
                                } catch (error) {
                                    this.hide()
                                    tool.message.hide()
                                    console.error(error)
                                }


                            },
                        },
                    ] : []),
                ],
            },
        ]


        return (
            <Ons.SplitterSide
                side='right'
                width={220}
                collapse={true}
                swipeable={false}
                isOpen={this.state.isOpen}
                onClose={this.hide.bind(this)}
                onOpen={this.show.bind(this)}>
                <Ons.Page className='AppSidePage'>
                    {listData.map((list, listIndex) => {
                        return (
                            <div
                                key={listIndex}
                            >
                                <Ons.ListTitle
                                    className='listTitle'
                                >{list.title}</Ons.ListTitle>
                                <Ons.List
                                    dataSource={list.list}
                                    className='list'

                                    renderRow={(row, rowIndex) => {
                                        return (
                                            <Ons.ListItem
                                                className={`listItem ${row.disabled ? 'listItemDisabled' : ''}`}
                                                key={rowIndex}
                                                expanded={true}
                                                tappable={(Boolean(row.onAction) && !row.disabled)}
                                                modifier={`${Boolean(row.onAction) ? 'chevron' : ''}`}
                                                onClick={() => {
                                                    !row.disabled && row.onAction && row.onAction(row)
                                                }}
                                            >
                                                <div
                                                    className='center'
                                                >
                                                    {row.title}
                                                </div>
                                            </Ons.ListItem>
                                        )
                                    }}
                                />
                            </div>
                        )
                    })}

                    {this._modalsView()}

                </Ons.Page>
            </Ons.SplitterSide>

        )
    }


    render() {
        const { lang = 'en' } = this.props.userData
        moment.locale(lang)
        return (
            <IntlProvider locale={'en'} messages={getLangPack(lang)}>
                <LocaleProvider
                    locale={(this._setLangAndt(lang) as any)}
                >
                    <Ons.Splitter id='App'>
                        {this._splitterSideView()}
                        <Ons.SplitterContent>
                            <Ons.Navigator
                                initialRoute={{ component: JJ_Loading, props: { key: 'loading' } }}
                                renderPage={this.renderPage}
                                ref={(navigator) => {
                                    if (navigator) {
                                        this.navigator = navigator
                                    }
                                }} />
                        </Ons.SplitterContent>
                    </Ons.Splitter>
                </LocaleProvider>
            </IntlProvider>
        )
    }
}



export default connector(App)

