import React, { ReactElement } from 'react'
import { ListView, PullToRefresh, ActivityIndicator } from 'antd-mobile'
import BoxNoDataPage from "../Box/BoxNoDataPage";
import { ListViewProps } from 'antd-mobile/lib/list-view';
import { FaSadTear } from 'react-icons/fa';
import tool from '../../tool';
import *as Ons from 'react-onsenui'
interface PageState {
    loadType: 'load' | 'normal' | 'error'
    data: {
        data: any[]
        totalCount: number
    }
    /**
     * 是否刷新
     */
    isRefreshing: boolean
    /**
    * 是否加载更多
    */
    isLoadMore: boolean
    /**
     * 是否正在加载更多
     */
    isOnLoadMore: boolean

    renderFooter?: ReactElement
}



export interface OnloadDataParams {
    from: number
    count: number
}


export type MyListData = {
    data: { id: string, [x: string]: any }[]
    totalCount: number;
}

export interface Props {
    renderHeader?: () => React.ReactElement<any>
    className?: string
    from?: number,
    count?: number,
    renderFooter?: ReactElement
    hideFooter?: boolean
    renderRow: (rowData: any, sectionID: string | number, rowID: string | number, highlightRow?: boolean) => React.ReactElement<any>
    noDataViewText?: string
    onLoadData(params: OnloadDataParams): Promise<MyListData>
    ListFooterComponent?(parms: MyListData): ReactElement
    renderSeparator?: (sectionID: string | number, rowID: string | number, adjacentRowHighlighted?: boolean) => React.ReactElement<any>;

}

type PageProps = Props


class BaseListView extends React.Component<PageProps, PageState> {
    dataSource: any;
    constructor(props: PageProps) {
        super(props);
        this.dataSource = new ListView.DataSource({ rowHasChanged: (row1: any, row2: any) => row1 !== row2, });
        this.state = {
            isRefreshing: false,
            loadType: 'load',
            data: {
                data: [],
                totalCount: 0,
            },
            isLoadMore: false,
            isOnLoadMore: false,
        }
    }

    componentDidMount() {
        this._initData()
    }

    componentWillUnmount() {
        this.setState = () => { }
    }

    /**
     * 初始化数据
     */
    _initData = async () => {
        try {
            this.setState({ loadType: 'load' })
            const from = (this.props.from || 0)
            const count = (this.props.count || 10)
            const res = await this.props.onLoadData({
                from,
                count,
            })
            this.setState({
                data: res,
                isLoadMore: res.totalCount > res.data.length,
                loadType: 'normal',
            })

        } catch (error) {
            console.log(error)
            this.setState({ loadType: 'error' })
        }
    }


    _onRefresh = async (): Promise<void> => {
        try {
            this.setState({ isRefreshing: true })
            const from = (this.props.from || 0)
            const count = (this.props.count || 10)
            const res = await this.props.onLoadData({
                from,
                count,
            })
            this.setState({
                data: res,
                isLoadMore: res.totalCount > res.data.length,
                isRefreshing: false,
            })

        } catch (error) {
            console.log(error)
            this.setState({ isRefreshing: false })

        }
    }


    _onLoadMore = async (): Promise<void> => {

        try {

            if (!this.state.isLoadMore) {
                return
            }

            if (this.state.isOnLoadMore) {
                return
            }

            this.setState({
                isOnLoadMore: true
            })
            const from = (this.props.from || 0) + this.state.data.data.length

            const res = await this.props.onLoadData({
                from,
                count: (this.props.count || 10),
            })
            this.state.data.data.push(...res.data)
            this.setState({
                data: this.state.data,
                isLoadMore: res.totalCount > this.state.data.data.length,
                isOnLoadMore: false,
            })
        } catch (error) {
            this.setState({
                isOnLoadMore: false,
            })
        }
    }


    _renderFooter = () => {
        return (
            <div className='renderFooter'>
                {
                    this.state.isLoadMore
                        ? <ActivityIndicator />
                        : this.props.renderFooter
                            ? this.props.renderFooter
                            : <span>{tool.intl.formatMessage({ id: 'baseListNoMoreShopDataMessage' })}</span>
                }
            </div>
        )
    }

    _noDataView = () => {
        const boxNoDataPage = {
            onClick: () => this._initData(),
            title: this.props.noDataViewText || 'noDataViewText'
        }
        return (
            <div className={'noDataView'}>
                <BoxNoDataPage
                    {...boxNoDataPage}
                />
            </div>
        )
    }
    _errorView = () => {
        return (
            <div className={'errorView'}>
                <FaSadTear className={'icon'} />
                <div>{tool.intl.formatMessage({ id: 'pageErrorNotLoadMessage' })}</div>
                <Ons.Button
                    onClick={() => this._initData()}
                    modifier='large material'
                    className='btn'
                >
                    {tool.intl.formatMessage({ id: 'buttonRefresh' })}
                </Ons.Button>
            </div>
        )
    }
    _normalView = () => {
        const node: any = undefined
        const data = this.state.data.data
        if (data.length <= 0) {
            return this._noDataView()
        }

        return (
            <div className={'normalView'}>
                <ListView
                    renderHeader={this.props.renderHeader}
                    renderSeparator={this.props.renderSeparator}
                    className={`list ${this.props.className || ''}`}
                    style={{ height: '100%' }}
                    dataSource={this.dataSource.cloneWithRows(this.state.data.data)}
                    renderFooter={this.props.hideFooter ? undefined : this._renderFooter}
                    /*pullToRefresh={<PullToRefresh
                        direction={node}
                        distanceToRefresh={node}
                        getScrollContainer={node}
                        indicator={{
                            activate: tool.intl.formatMessage({ id: 'baseListPullToRefreshRelease' }),
                            deactivate: tool.intl.formatMessage({ id: 'baseListPullToRefreshPull' }),
                            release: tool.intl.formatMessage({ id: 'baseListPullToRefreshLoading' }),
                            finish: tool.intl.formatMessage({ id: 'baseListPullToRefreshFinish' }),
                        }}
                        refreshing={this.state.isRefreshing}
                        onRefresh={this._onRefresh}
                    />}*/
                    renderRow={this.props.renderRow}//每一行的组件
                    onEndReached={this._onLoadMore}//加载更多回调
                    onEndReachedThreshold={400}//加载更多临界触发
                />
            </div>


        )
    }
    _loadingView = () => {
        return (
            <div className={'loadingView'}>
                <ActivityIndicator
                    animating={true}
                />
            </div>

        )
    }


    render() {
        return (
            <div
                id='BaseListView'
            >
                {this.state.loadType === 'error' && this._errorView()}
                {this.state.loadType === 'normal' && this._normalView()}
                {this.state.loadType === 'load' && this._loadingView()}
            </div>
        );
    }

}

export default BaseListView
