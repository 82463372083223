import React from 'react'
import queryString from 'query-string';
import *as ActionUser from '../../actions/ActionUser'
import *as ActionArea from '../../actions/ActionArea'
import *as Ons from 'react-onsenui'
import tool from '../../tool';
import { injectIntl, InjectedIntlProps } from "react-intl";
import { ConnectedProps, connect } from 'react-redux';
import api, { PostAuthSessionRequestNewParameters, PostAuthSessionRequestNewRes, GetAreaModuleListParameters } from '../../api';
import JJ_Components from '../../components/JJ_Components'

interface PageState {

}


interface Props {
    dispatch: any
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>



interface Page {

}


interface RootState {
    user: any
}

const mapState = (state: RootState) => ({
    userData: state.user,

})

const mapDispatch = {
    authOut: ActionUser.authOut,
    getAreaModuleList: ActionArea.getAreaModuleList,
    getUserData: ActionUser.getUser,
    getUserSettingData: ActionUser.getUserSettingData,
    getAreaList: ActionArea.getAreaList,
}

export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_Loading extends React.Component<PageProps, PageState> implements Page {
    constructor(props: PageProps) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        tool.intl.getIntl(this.props.intl)
    }


    _getUserData = async () => {
        const res = await this.props.getUserData()
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
                case 'NOT_FOUND': {

                }
                    break
            }
            throw res.kind
        }
    }
    _getAreaModuleList = async (params: GetAreaModuleListParameters) => {
        const res = await this.props.getAreaModuleList(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
            }
            throw res.kind
        }
    }
    _getUserSettingData = async () => {
        const res = await this.props.getUserSettingData()
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
            }
            throw res.kind
        }
    }



    /**
     * 初始化数据
     */
    _initData = async () => {

        const loadShopCampaign = async (areaId: string) => {
            try {
                const getAreaModuleListRes = await this._getAreaModuleList({ areaId, type: 'SHOP' })
                const areaModuleData = getAreaModuleListRes.data

                const areaModuleWeshop = areaModuleData.find(areaModule => areaModule.isEnabled && (areaModule.name === 'WESHOP' || areaModule.name === 'GROUP_BUY'));

                if (!areaModuleWeshop) {
                    tool.navigator.resetPage({ key: 'JJ_AreaModuleDisabled' }, { animation: 'node' })
                    return
                }
                const { attrs }: any = areaModuleWeshop ? areaModuleWeshop : {}
                tool.navigator.resetPage({
                    key: 'JJ_ShopCampaign',
                    props: { tags: areaModuleWeshop.attrs.CAMPAIGN_TAGS }
                }, { animation: 'node' })

                // check orderId
                const urlParams = queryString.parse(location.search);

                if (urlParams.orderId) {
                    tool.navigator.pushPage({
                        key: 'JJ_ShopOrderDetails',
                        props: {
                            orderId: urlParams.orderId.toString(),
                            moduleId: areaModuleWeshop.id,
                        }
                    }, { animation: 'node' })
                }

            } catch (error) {
                throw error
            }

        }

        return (async () => {
            // check one-time auth
            const urlParams = queryString.parse(location.search);

            if (urlParams.authToken) {
                try {
                    const authData = await api.postAuthOneTimeVerify({
                        token: urlParams.authToken.toString(),
                    });

                    const userData = await this._getUserData()
                } catch (error) {
                    console.log(error)
                }
            }

            try {
                const userData = await this._getUserData()
                await this._getUserSettingData()
                const areaId = userData.userAreas.HOME[0]
                if (areaId) {
                    await loadShopCampaign(areaId)
                    return

                } else {
                    await this.props.authOut()
                    return
                }

            } catch (error) {
                console.log(error)
                if (error !== 'NOT_LOGGED_IN') {
                    await this.props.authOut()
                }
            }
        })()
    }

    render() {
        return (
            <Ons.Page>

                <JJ_Components.boxPage
                    loadData={this._initData}
                />
            </Ons.Page>
        )
    }
}




export default connector(injectIntl(JJ_Loading))
