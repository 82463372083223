import React from 'react'
import { ActivityIndicator } from 'antd-mobile'
import { FaSadTear } from 'react-icons/fa';
import { Button } from 'antd-mobile'
import tool from '../../tool';

interface PageState {
    pageType: 'loading' | 'normal'
    loadType: 'loading' | 'normal' | 'error'
}


interface Props {
    onData?(data: any): void
    loadData(): Promise<any>
}


type PageProps = Props



class BoxPage extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props)
        this.state = {
            pageType: 'loading',
            loadType: 'loading',
        }
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ pageType: 'normal' })
        }, 1000)

        this._loadData()
    }

    componentWillUnmount() {
        this.setState = () => { }
    }


    _loadData = async () => {

        this.setState({ loadType: 'loading' })
        try {
            const { loadData, onData } = this.props
            const res = await loadData()
            onData && onData(res)
            this.setState({ loadType: 'normal' })
        } catch (error) {
            this.setState({ loadType: 'error' })
        }
    }


    _loadView = () => {
        return (
            <div className='loadView'>
                <ActivityIndicator
                    animating={true}
                />
            </div>
        )
    }

    _errorView = () => {
        return (
            <div className='errorView'>
                <FaSadTear className='icon' />
                <div>{tool.intl.formatMessage({ id: 'pageErrorNotLoadMessage' })}</div>
                <Button
                    onClick={() => this._loadData()}
                    type='warning' className='btn'>{tool.intl.formatMessage({ id: 'boxPageButtonRefresh' })}</Button>
            </div>
        )
    }

    render() {

        return (
            <div id='BoxPage'>
                {this.state.loadType === 'loading' && this._loadView()}
                {this.state.loadType === 'normal' && this.props.children}
                {this.state.loadType === 'error' && this._errorView()}

                {this.state.pageType !== 'normal' && <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        backgroundColor: 'rgba(0,0,0,0.01)',
                        opacity: 0,
                    }}
                />}

            </div>

        )
    }
}

export default BoxPage
