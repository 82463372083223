import React from 'react';
import *as Ons from 'react-onsenui'
import JJ_UserDeliveryAddress from '../JJ_UserDeliveryAddress'
import { GetShopDeliveryRes } from '../../../api';
import tool from '../../../tool';



interface PageState {
    deliveryAddressId: string
}

export interface Props {
    delivery: GetShopDeliveryRes
    deliveryAddressId: string
    onSelect(props: PageProps): void
    amount: number
    checked: boolean
}



type PageProps = Props


class JJ_UserDeliveryListItem extends React.Component<PageProps, PageState> {

    componentDidMount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            deliveryAddressId: props.deliveryAddressId,
        }

    }


    _onSelect = () => {
        this.props.onSelect(this.props)
    }

    _titleView = () => {

        const {
            name,

        } = this.props.delivery
        return (
            <div className='titleView'>
                <Ons.Radio
                    className='checked'
                    onChange={event => this._onSelect()}
                    modifier='material'
                    checked={this.props.checked}//选中
                />
                <span className='name'>{name}</span>
            </div>
        )

    }
    _centerView = () => {
        const {
            type,
            description,
        } = this.props.delivery
        return (
            <div className='centerView'>
                <span className='description'>{description}</span>
                {/* {type === 'DIRECT' && this.props.checked && (
                    <JJ_UserDeliveryAddress
                        checked={this.props.checked}
                        className='JJ_UserDeliveryAddress'
                        onSelect={(deliveryAddressId: string) => this.setState({ deliveryAddressId }, () => this._onSelect())}
                        deliveryAddressId={this.state.deliveryAddressId}
                    />
                )} */}
            </div>
        )

    }
    _shippingFeeView = () => {
        const {
            shippingFee,
            shippingFeeWaivingAmount,

        } = this.props.delivery
        return (
            <div className='shippingFeeView'>
                {
                    shippingFee > 0 && (!shippingFeeWaivingAmount || this.props.amount < shippingFeeWaivingAmount) ? (
                        <span className='shippingFee'>
                            {tool.intl.formatMessage({ id: '运费' })}: + ${tool.math.initPrice({ price: shippingFee }).toFixed()}
                        </span>
                    ) : (
                            <span className='shippingFee wavied'>
                                {tool.intl.formatMessage({ id: 'userDeliveryShippingFeeWavied' })}
                            </span>
                        )
                }
                {shippingFeeWaivingAmount && shippingFeeWaivingAmount > 0 &&
                    <span
                        className='shippingFeeWaivingAmount'>
                        {tool.intl.formatMessage({ id: 'userDeliveryShippingFeeWaviedDescription' }, { number: tool.math.initPrice({ price: shippingFeeWaivingAmount }).toFixed() })}
                    </span>}
            </div>
        )

    }

    render() {
        return (
            <div
                id="JJ_UserDeliveryListItem"
                onClick={() => this._onSelect()}
            >
                {this._titleView()}
                {this._shippingFeeView()}
                {this._centerView()}
        </div>
        );
    }
}



export default JJ_UserDeliveryListItem
