import React from 'react'
import { FaRegKiss } from 'react-icons/fa';
import orderPng from '../../imgs/order.png'
interface PageState {

}

export interface Props {
    onClick(): void
    title: string
}

type PageProps = Props


class BoxNoDataPage extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }


    render() {
        return (
            <div
                id={'BoxNoDataPage'}
                onClick={() => this.props.onClick()}
            >
                <div
                    className='imgBox'
                >
                    <img
                        className='img'
                        src={orderPng}
                    />
                </div>
                <div className = 'title'>{this.props.title}</div>
            </div>
        )
    }
}

export default BoxNoDataPage
